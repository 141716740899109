import React, { useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import {
  Box,
  Button,
  Card,
  Chip,
  CssBaseline,
  Divider,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "../../components/IconButton/IconButton";
import Icons from "../../assets/Icons";
import Header from "../../components/AppDrawer/Header";
import axios from "axios";
import AXIOS from "../Utils/AXIOS";
import toast, { Toaster } from "react-hot-toast";
import "./Style.css";
import CustomLabel from "../../components/CustomLabel/CustomLabel";
import Collapsible from "react-collapsible";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import MainScreen from "../../components/AppDrawer/MainScreen";
import { APPROVAL_CENTER } from "../Utils/Routes";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const drawerWidth = 0;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ApprovalCenter() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [addGroupModalFlag, setAddGroupModalFlag] = useState(false);
  const [addApproverModalFlag, setAddApproverModalFlag] = useState(false);
  const [enteredGroupData, setEnteredGroupData] = useState({
    NAME: "Group Name",
    LABEL: "Group Name",
    IS_REQUIRED: true,
    IS_DISABLED: false,
    ERROR_FLAG: false,
    VALUE: "",
  });

  const ScreenNames = [
    {
      label: "Customer",
      value: "CUSTOMER",
    },
    {
      label: "Vendor",
      value: "VENDOR",
    },
  ];

  const [allApproverFields, setAllApproverFields] = useState([
    // {
    //   TITLE: "Screen Name*",
    //   VALUE: "",
    //   IS_DISABLED: false,
    //   DROPDOWN: true,
    //   KEY: "SCREEN_ID",
    //   REQUIRED: true,
    //   ERROR_FLAG: false,
    //   DROPDOWN_OPTIONS: [
    //     {
    //       label: "Customer",
    //       value: "CUSTOMER",
    //     },
    //     {
    //       label: "Vendor",
    //       value: "VENDOR",
    //     },
    //   ],
    // },
    {
      TITLE: "Group Name*",
      VALUE: "",
      IS_DISABLED: false,
      DROPDOWN: true,
      KEY: "GROUP_ID",
      REQUIRED: true,
      ERROR_FLAG: false,
      DROPDOWN_OPTIONS: [],
    },

    {
      TITLE: "Approver*",
      VALUE: "",
      IS_DISABLED: false,
      DROPDOWN: true,
      KEY: "EMP_ID",
      REQUIRED: true,
      MULTI: true,
      ERROR_FLAG: false,
      DROPDOWN_OPTIONS: [
        // {
        //   label: "Rahul",
        //   value: "E0001",
        // },
        // {
        //   label: "Joyeeta",
        //   value: "E0002",
        // },
      ],
    },
    {
      TITLE: "Edit Application*",
      VALUE: "",
      IS_DISABLED: false,
      DROPDOWN: true,
      KEY: "CAN_EDIT",
      REQUIRED: true,
      ERROR_FLAG: false,
      DROPDOWN_OPTIONS: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
    },
    {
      TITLE: "Email Enabled*",
      VALUE: "",
      IS_DISABLED: false,
      DROPDOWN: true,
      KEY: "IS_EMAIL_ENABLED",
      REQUIRED: true,
      ERROR_FLAG: false,
      DROPDOWN_OPTIONS: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
    },
    // {
    //   TITLE: "Is Action Reversable*",
    //   VALUE: "",
    //   IS_DISABLED: false,
    //   DROPDOWN: true,
    //   KEY: "ACTION_REVERSABLE",
    //   REQUIRED: true,
    //   ERROR_FLAG: false,
    //   DROPDOWN_OPTIONS: [
    //     {
    //       label: "Yes",
    //       value: true,
    //     },
    //     {
    //       label: "No",
    //       value: false,
    //     },
    //   ],
    // },
  ]);
  useEffect(() => {
    getGroupsList();
    getUsersList();
  }, []);

  const getUsersList = () => {
    axios.get(AXIOS.axiosUrl + AXIOS.users_get).then((response) => {
      let tempGroupOptions = [];
      response.data.map((val) => {
        tempGroupOptions.push({ label: val.USER_NAME, value: val.USER_ID });
      });
      let tempAllApproverFields = [...allApproverFields];
      console.log("asdasdasd", tempAllApproverFields);

      tempAllApproverFields.map((val) => {
        console.log("asdasdas", val);
        if (val.KEY == "EMP_ID") {
          val.DROPDOWN_OPTIONS = tempGroupOptions;
        }
      });
      setApprovalFieldData(tempAllApproverFields);
    });
  };

  const getGroupsList = () => {
    axios
      .get(AXIOS.axiosUrl + AXIOS.approver_group_get)
      .then((response) => {
        console.log("sadsad", response.data);
        let tempGroupOptions = [];
        response.data.map((val) => {
          tempGroupOptions.push({ label: val.GROUP_NAME, value: val.GROUP_ID });
        });
        let tempAllApproverFields = [...allApproverFields];
        console.log("asdasdasd", tempAllApproverFields);

        tempAllApproverFields.map((val) => {
          console.log("asdasdas", val);
          if (val.KEY == "GROUP_ID") {
            val.DROPDOWN_OPTIONS = tempGroupOptions;
          }
        });
        setApprovalFieldData(tempAllApproverFields);
      })
      .catch((err) => {
        console.log("sadsad", err);
      });
  };
  const [approvalFieldData, setApprovalFieldData] = useState([]);
  const [approverList, setApproverList] = useState([]);

  useEffect(() => {
    // setApprovalFieldData(allApproverFields);
    getApproverData();
  }, []);

  const getApproverData = () => {
    axios
      .get(AXIOS.axiosUrl + AXIOS.get_approver)
      .then((response) => {
        console.log("ASDasdasdsad asdasd", response.data);
        response.data.map((val) => {
          const foundScreenName = ScreenNames.find(
            (item) => item.value == val.SCREEN_ID
          );

          if (foundScreenName) {
            val.ENTERED_SCREEN_DATA = foundScreenName;
          } else {
            val.ENTERED_SCREEN_DATA = {
              label: "Select Screen",
              value: "SELECT_SCREEN",
            };
          }
        });
        response.data.map((val) => {
          val.APPROVERS.sort((a, b) => a.LEVEL - b.LEVEL);
        });
        setApproverList(response.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const createNewApprover = () => {
    let approverPayload = {};
    let foundError = false;
    let tempApprovalFieldData = [...approvalFieldData];
    tempApprovalFieldData.map((val) => {
      console.log("vll", val.VALUE);
      if (val.REQUIRED == true && val.DROPDOWN == true) {
        if (val.VALUE?.value != undefined) {
          approverPayload[val.KEY] = val?.VALUE.value;
        } else {
          val.ERROR_FLAG = true;
        }
      } else if (val.REQUIRED == false && val.DROPDOWN == true) {
        if (val.VALUE?.value != undefined) {
          approverPayload[val.KEY] = val?.VALUE.value;
        } else {
          approverPayload[val.KEY] = val?.VALUE;
        }
      }
      if (val.REQUIRED == true && val.DROPDOWN == false) {
        if (val.VALUE != "") {
          approverPayload[val.KEY] = val?.VALUE;
        } else {
          val.ERROR_FLAG = true;
        }
      } else if (val.REQUIRED == false && val.DROPDOWN == false) {
        approverPayload[val.KEY] = val?.VALUE;
      }
    });
    //   {
    //   "GROUP_NAME":"A",
    //   "GROUP_ID":"G0001",
    //   "SCREEN_ID":"CUSTOMER",
    //   "EMP_ID":"E0001",
    //   "ELIGIBLE":false,
    //   "CAN_EDIT":false,
    //   "IS_EMAIL_ENABLED":true,
    //   "LEVEL":1,
    //   "IS_DEFAULT":true
    // },
    console.log("approverPayload", [
      { ...approverPayload, LEVEL: "", ELIGIBLE: false, IS_DEFAULT: false },
    ]);
    // setApprovalFieldData(tempApprovalFieldData);
    if (!foundError) {
      axios
        .post(AXIOS.axiosUrl + AXIOS.create_approver, [
          { ...approverPayload, LEVEL: "", ELIGIBLE: false, IS_DEFAULT: false },
        ])
        .then((response) => {
          // setApprovalFieldData(allApproverFields);
          toast.success("New approver created");
          getApproverData();
          setAddApproverModalFlag(false);
          // getApproverData()
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const createNewGroup = () => {
    if (enteredGroupData.VALUE != "") {
      axios
        .post(AXIOS.axiosUrl + AXIOS.approver_group_create, {
          GROUP_NAME: enteredGroupData.VALUE,
        })
        .then((response) => {
          toast.success("New Group Created");
          setAddGroupModalFlag(false);
          getGroupsList();
        })
        .catch((err) => {
          console.log("sadasd", err);
        });
    } else {
      setEnteredGroupData((prev) => ({
        ...prev,
        ERROR_FLAG: true,
      }));
    }
  };

  const handleInputChange = (CategoryId, FieldId, Index, value) => {
    const TypedValue = value;

    let tempSelectedData = [...approvalFieldData];
    console.log("tempSelectedData", tempSelectedData);
    if (tempSelectedData[Index]?.MAX_LENGTH != undefined) {
      if (TypedValue.length <= tempSelectedData[Index].MAX_LENGTH) {
        tempSelectedData[Index].VALUE = TypedValue;
        tempSelectedData[Index].ERROR_FLAG = false;
      } else {
        toast.error("Maximum word limit exceeded", {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
          iconTheme: {
            primary: "#713200",
            secondary: "#FFFAEE",
          },
        });
      }
    } else {
      tempSelectedData[Index].VALUE = TypedValue;
      tempSelectedData[Index].ERROR_FLAG = false;
    }

    setApprovalFieldData(tempSelectedData);
  };

  const handleScreenDropDown = (index, value) => {
    let tempApproverList = [...approverList];
    tempApproverList[index].ENTERED_SCREEN_DATA = value;

    console.log("Asdadas", tempApproverList);

    setApproverList(tempApproverList);
  };

  const updateScreenName = (ScreenGroupData) => {
    console.log("asdasdsa", ScreenGroupData);
    if (ScreenGroupData?.ENTERED_SCREEN_DATA != undefined) {
      let tempScreenGroupPayload = [];
      ScreenGroupData.APPROVERS.map((val) => {
        tempScreenGroupPayload.push({
          ...val,
          SCREEN_ID: ScreenGroupData.ENTERED_SCREEN_DATA.value,
        });
      });
      console.log("ASdasdas", tempScreenGroupPayload);

      axios
        .post(
          AXIOS.axiosUrl + AXIOS.default_approver_update,
          tempScreenGroupPayload
        )
        .then((response) => {
          toast.success("Changes Saved");
          console.log("asdasd", response.data);
          getApproverData();
        })
        .catch((err) => {
          console.log("asdasd", err);
        });
    } else {
      toast.error("Please select Screen name");
    }
  };

  const [draggingItem, setDraggingItem] = useState({});

  const shiftApprover = (mainIndex, subIndex, Action) => {
    let tempApproverList = [...approverList];

    // console.log(
    //   "ASdasdsa,",
    //   tempApproverList[mainIndex].APPROVERS.length,
    //   subIndex
    // );
    if (
      Action == "DOWN" &&
      tempApproverList[mainIndex].APPROVERS.length - 1 > subIndex
    ) {
      let temp = tempApproverList[mainIndex].APPROVERS[subIndex].LEVEL;
      console.log(
        "ASdasdas",
        temp,
        tempApproverList[mainIndex].APPROVERS[subIndex + 1].LEVEL
      );

      tempApproverList[mainIndex].APPROVERS[subIndex].LEVEL =
        tempApproverList[mainIndex].APPROVERS[subIndex + 1].LEVEL;

      tempApproverList[mainIndex].APPROVERS[subIndex + 1].LEVEL = temp;
      console.log(
        "ASdasdas",
        temp,
        tempApproverList[mainIndex].APPROVERS[subIndex].LEVEL,
        tempApproverList[mainIndex].APPROVERS[subIndex + 1].LEVEL
      );
    }
    if (Action == "UP" && 0 < subIndex) {
      let temp = tempApproverList[mainIndex].APPROVERS[subIndex].LEVEL;
      console.log(
        "ASdasdas",
        temp,
        tempApproverList[mainIndex].APPROVERS[subIndex - 1].LEVEL
      );

      tempApproverList[mainIndex].APPROVERS[subIndex].LEVEL =
        tempApproverList[mainIndex].APPROVERS[subIndex - 1].LEVEL;

      tempApproverList[mainIndex].APPROVERS[subIndex - 1].LEVEL = temp;
      console.log(
        "ASdasdas acbd"
        // tempApproverList[mainIndex].APPROVERS[subIndex + 1]
      );
    }

    tempApproverList.map((val) => {
      val.APPROVERS.sort((a, b) => a.LEVEL - b.LEVEL);
    });

    console.log("sadasdkjasbduhb asiudasuhd", tempApproverList);

    setApproverList(tempApproverList);
  };
  return (
    <MainScreen drawerWidth={282} ActiveKey={APPROVAL_CENTER}>
      <Toaster />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 15,
          backgroundColor: "#fff",
          padding: 5,
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div className="gray-arrow-container">Admin</div>
          <div className="blue-arrow-container">Approval Center</div>
        </div>
        <div>
          <button
            className="blue-button"
            onClick={() => {
              setAddGroupModalFlag(true);
            }}
            style={{
              marginRight: 10,
            }}
          >
            Add Group +
          </button>
          <button
            className="blue-button"
            onClick={() => {
              setAddApproverModalFlag(true);
            }}
          >
            Add Approver +
          </button>
        </div>
      </div>
      {/* <Box className="approval-modal-buttons">
        <button
          className="save-button"
          onClick={() => {
            setAddGroupModalFlag(true);
          }}
        >
          Add Group
        </button>
        <button
          className="save-button"
          onClick={() => {
            setAddApproverModalFlag(true);
          }}
        >
          Add Approver
        </button>
      </Box> */}
      <Box className="approver-collapsible-container">
        {approverList.map((item, index) => {
          return (
            <Card>
              <h4
                style={{
                  marginLeft: 7,
                }}
              >
                {item.GROUP_NAME}({item.GROUP_ID})
              </h4>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "space-between",
                    // marginLeft: 1,
                  }}
                >
                  <CustomDropdown
                    Options={ScreenNames}
                    Value={item.ENTERED_SCREEN_DATA}
                    Label={"Screen"}
                    OnChange={(e) => {
                      handleScreenDropDown(index, e);
                    }}
                  />
                  {item?.SCREEN_ID != "" && item?.SCREEN_ID != undefined && (
                    // <Chip color="primary" label="Default" sx={{ ml: 1 }} />
                    <div className="default-chip">Default</div>
                  )}
                </Box>

                <button
                  className="blue-button"
                  onClick={() => {
                    updateScreenName(item);
                  }}
                  style={{
                    marginRight: 5,
                  }}
                >
                  Save
                </button>
              </Box>

              {item.APPROVERS.map((val, subindex) => {
                console.log("ASdasdasd", val);
                return (
                  <div
                    // draggable="true"
                    // onDrag={() => {}}
                    // className={`item ${
                    //   // item === this.state.draggingItem ? "dragging" : ""
                    // }`}
                    // draggable="true"
                    // onDragStart={(e) => handleDragStart(e, val)}
                    // onDragEnd={handleDragEnd}
                    // onDragOver={handleDragOver}
                    // onDrop={(e) => handleDrop(e, val)}
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        width: "90%",
                      }}
                    >
                      <Collapsible
                        trigger={
                          <div className="collapsiable-item-header">
                            <p>
                              {val.EMP_NAME} ({val.EMP_ID})
                            </p>
                            <KeyboardArrowUpIcon />
                          </div>
                        }
                      >
                        <div className="collapsible-body-container">
                          <div className="collapsiable-item">
                            <p className="collapsiable-item-title">
                              Edit Application:
                            </p>
                            <p className="collapsiable-item-content">
                              {val.CAN_EDIT ? "Yes" : "No"}
                            </p>
                          </div>
                          <div className="collapsiable-item">
                            <p className="collapsiable-item-title">
                              Email Enabled:
                            </p>
                            <p className="collapsiable-item-content">
                              {val.IS_EMAIL_ENABLED ? "Yes" : "No"}
                            </p>
                          </div>
                        </div>
                      </Collapsible>
                    </div>

                    <div
                      style={{
                        width: "15%",
                        display: "flex",
                        // flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <Tooltip title="Shift Up">
                          <Button
                            sx={{
                              height: 20,
                              width: 10,
                            }}
                            onClick={() => {
                              shiftApprover(index, subindex, "UP");
                            }}
                          >
                            <KeyboardArrowUpIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Shift Down">
                          <Button
                            sx={{
                              height: 20,
                              width: 10,
                            }}
                            onClick={() => {
                              shiftApprover(index, subindex, "DOWN");
                            }}
                          >
                            <KeyboardArrowDownIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Card>
          );
        })}
      </Box>

      <Modal
        open={addGroupModalFlag}
        onClose={() => {
          setAddGroupModalFlag(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Add Group</h3>
            <IconButton
              Icon={Icons.Close}
              style={{
                height: 15,
                width: 15,
                cursor: "pointer",
              }}
              onClick={(e) => {
                setAddGroupModalFlag(false);
              }}
            />
          </div>
          <Divider />

          <CustomLabel LabelText={enteredGroupData.LABEL} />
          <Box>
            <input
              className="add-group-input"
              // type="search"
              disabled={enteredGroupData.IS_DISABLED}
              placeholder={enteredGroupData.LABEL}
              Type={enteredGroupData.INPUT_TYPE}
              onChange={(e) => {
                setEnteredGroupData((prev) => ({
                  ...prev,
                  VALUE: e.target.value,
                  ERROR_FLAG: false,
                }));
              }}
              value={enteredGroupData.VALUE}
            />
            {/* <CustomInput
              Disabled={enteredGroupData.IS_DISABLED}
              // id={enteredGroupData.FIELD_ID}
              Placeholder={enteredGroupData.LABEL}
              Type={enteredGroupData.INPUT_TYPE}
              isRequired={enteredGroupData.IS_REQUIRED}
              error={enteredGroupData.ERROR_FLAG}
              Value={enteredGroupData.VALUE}
              onChange={(e) => {
                setEnteredGroupData((prev) => ({
                  ...prev,
                  VALUE: e.target.value,
                  ERROR_FLAG: false,
                }));
              }}
              helperText={
                enteredGroupData.ERROR_FLAG ? "Please enter Group Name" : ""
              }
            /> */}
            <button
              className="blue-button"
              style={{
                width: "100%",
                marginTop: 10,
              }}
              onClick={() => {
                createNewGroup();
              }}
            >
              Create
            </button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={addApproverModalFlag}
        onClose={() => {
          setAddApproverModalFlag(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>Add Approver</h3>
            <IconButton
              Icon={Icons.Close}
              style={{
                height: 15,
                width: 15,
                cursor: "pointer",
              }}
              onClick={(e) => {
                setAddApproverModalFlag(false);
              }}
            />
          </div>
          <Divider />
          <Box>
            {approvalFieldData.map((val, index) => {
              return (
                <div>
                  <p>{val.TITLE}</p>
                  {val.DROPDOWN && (
                    <>
                      <CustomDropdown
                        Options={val.DROPDOWN_OPTIONS}
                        Value={val.VALUE}
                        Disabled={val.IS_DISABLED}
                        error={val.ERROR_FLAG}
                        Label={val.TITLE}
                        OnChange={(e) => {
                          handleInputChange(
                            val.CATEGORY_ID,
                            val.FIELD_ID,
                            index,
                            e
                          );
                        }}
                      />
                      {val.ERROR_FLAG && (
                        <Typography
                          sx={{
                            fontSize: 12,
                            // ml: 2,
                            padding: 0.6,
                            // m:1,
                            pl: 1,
                            color: "red",
                            backgroundColor: "white",
                          }}
                        >
                          Please select {val.TITLE}
                        </Typography>
                      )}
                    </>
                  )}
                  {!val.DROPDOWN && <CustomInput Placeholder={val.TITLE} />}
                </div>
              );
            })}
            <button
              className="blue-button"
              style={{
                width: "100%",
                marginTop: 20,
              }}
              onClick={() => {
                createNewApprover();
              }}
            >
              Create
            </button>
          </Box>
        </Box>
      </Modal>
    </MainScreen>
  );
}

export default ApprovalCenter;
