const initialState = {
  is_auth: false,
  auth_data: {},

};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "AUTH_DATA_GET":
      return {
        ...state,
        is_auth: action.is_auth,
        auth_data: action.auth_data,
      };

    default:
      return state;
  }
};

export default auth;
