export const PROFILE = "/v1/profile";
export const SETTINGS = "/v1/settings";
export const DASHBOARD = "/v1/dashboard";
export const VENDOR_CREATION = "/v1/vendor-creation";
export const VENDOR = "/v1/vendor";
export const VENDOR_DETAILS = "/v1/vendor-details";
export const VENDOR_APPROVAL = "/v1/vendor-approval";
export const CUSTOMER_CREATION = "/v1/customer-creation";
export const CUSTOMER = "/v1/customer";
export const CUSTOMER_APPROVAL = "/v1/customer-approval";
export const APPROVAL_CENTER = "/v1/approval-center";
export const CONTROL_CENTER = "/v1/control-center";
export const ROLES_PERMISSION = "/v1/roles-permission";
export const CUSTOMER_EMAIL_CREATION = "/v1/customer-e-creation";
