import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
// import axios from "../Utils/CustomAxios";
import AXIOS from "../Utils/AXIOS";
import CustomLabel from "../../components/CustomLabel/CustomLabel";
import { Card, CssBaseline, Divider, TextField } from "@mui/material";
import "./style.css";
import IconButton from "../../components/IconButton/IconButton";
import IMAGES from "../../assets/IMAGES";
import Icons from "../../assets/Icons";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomInput from "../../components/CustomInput/CustomInput";
import * as xlsx from "xlsx";
import Header from "../../components/AppDrawer/Header";
import toast, { Toaster } from "react-hot-toast";
import MainScreen from "../../components/AppDrawer/MainScreen";
import { CONTROL_CENTER } from "../Utils/Routes";

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}-${new Date().getTime()}`,
    content: `item ${k + offset}`,
  }));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ScreenList = [
  {
    label: "Customer",
    value: "CUSTOMER",
  },
  {
    label: "Vendor",
    value: "VENDOR",
  },
];

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  let tempDelete = { ...removed };

  tempDelete.CATEGORY_ID = destClone[0].CATEGORY_ID;
  tempDelete.CATEGORY = destClone[0].CATEGORY;

  destClone.splice(droppableDestination.index, 0, tempDelete);

  if (sourceClone.length == 0) {
    sourceClone.push({
      CATEGORY: removed.CATEGORY,
      CATEGORY_ID: removed.CATEGORY_ID,
    });
  }

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 1,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "null",
  border: 1,
  borderColor: "red",

  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "null",
  border: 1,
  borderColor: "red",

  // padding: grid,

  // width: 150,
});

function AdminControlCenter() {
  const [state, setState] = useState([[], []]);
  const [tbody, setTbody] = useState([]);
  const [mainTbody, setMainTbody] = useState([]);
  const [selectedModuleName, setSelectedModuleName] = useState(ScreenList[0]);

  useEffect(() => {
    getSapFields();
  }, [selectedModuleName]);

  const getSapFields = () => {
    let selectedScreenApiUrl;
    if (selectedModuleName.value == "CUSTOMER") {
      selectedScreenApiUrl = AXIOS.sapFieldGet;
    } else if (selectedModuleName.value == "VENDOR") {
      selectedScreenApiUrl = "sadadas";
    }
    if (!selectedScreenApiUrl) return;
    console.log("Asdsada", AXIOS.axiosUrl + selectedScreenApiUrl);
    axios.get(AXIOS.axiosUrl + selectedScreenApiUrl).then((response) => {
      let tempResponse = [];
      let tempFields = [];
      let uniqueCatID = [];

      // let temp=response.data

      // response.data.sort(function (a, b) {
      //   return a.index - b.index;
      // });

      // console.log("aSASAs", temp);
      console.log("asdasdsadasdsad", response.data);
      let tempResponseData = [];
      // response.data.map((val) => {
      //   const index = tempResponseData.findIndex(
      //     (item) => item.FIELD_ID == val.FIELD_ID
      //   );
      //   if (index == -1) {
      //     tempResponseData.push({ ...val });
      //   } else {
      //     tempResponseData[index] = { ...val };
      //   }
      // });

      response.data.map((val, index) => {
        let categoryIndex = uniqueCatID.findIndex(
          (item) => val.CATEGORY_ID == item.CATEGORY_ID
        );
        if (categoryIndex == -1) {
          uniqueCatID.push({ CATEGORY_ID: val.CATEGORY_ID });
        }
        tempResponse.push({ id: val.FIELD_ID, ...val });
      });


      uniqueCatID.map((val, index) => {
        const FieldData = tempResponse.filter(
          (data) => data.CATEGORY_ID == val.CATEGORY_ID
        );
        if (FieldData) {
          tempFields.push(FieldData);
        }
      console.log("sdadas", FieldData);

      });
      setState(tempFields);
    });
  };

  function onDragEnd(result) {
    const { source, destination } = result;
    console.log("ASdasdsad", source, destination);
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(state[sInd], source.index, destination.index);
      const newState = [...state];
      newState[sInd] = items;
      setState(newState);
    } else {
      const result = move(state[sInd], state[dInd], source, destination);
      const newState = [...state];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];
      setState(newState.filter((group) => group.length));
    }
    
  }

  const [newTabName, setNewTabName] = useState("");
  const [fieldClickedData, setFieldClickedData] = useState({});

  const addTab = () => {
    if (newTabName != "") {
      let tempState = [...state];

      tempState.push([
        { CATEGORY: newTabName, CATEGORY_ID: "", IS_CATEGORY: true },
      ]);
      setState(tempState);
    }
  };

  const AssignFieldsToEmp = () => {
    let tempState = [...state];

    tempState.map((val, valIndex) => {
      // if(val.length==0){
      //   alert("length 0")
      // }

      val.map((innerVal, innerValIndex) => {
        if (innerVal.FIELD_ID == "" || innerVal.FIELD_ID == undefined) {
          tempState[valIndex].splice(innerValIndex, 1);
        }
      });
    });

    tempState.map((val, index) => {
      console.log("ASasdas", typeof val === "object" && Array.isArray(val));
      if (val.length == 0) {
        tempState.splice(index, 1);
      }
    });

    console.log("asdkjabsdsa click", JSON.stringify(tempState));

    axios
      .post(AXIOS.axiosUrl + AXIOS.assign_fields_emp, tempState)
      .then((response) => {
        toast.success("Fields Updated successful");
        getSapFields();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    console.log("asdkjabsdsa", state);
  }, [state]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, clickedItem, mainIndex, subIndex) => {
    setAnchorEl(event.currentTarget);

    setFieldClickedData({
      ...clickedItem,
      MAIN_INDEX: mainIndex,
      SUB_INDEX: subIndex,
      IS_REQUIRED: {
        label: `${clickedItem.IS_REQUIRED}`,
        value: clickedItem.IS_REQUIRED,
      },
      FIELD_TYPE: {
        label: `${clickedItem.FIELD_TYPE}`,
        value: clickedItem.FIELD_TYPE,
      },
      INPUT_TYPE: {
        label: `${clickedItem.INPUT_TYPE}`,
        value: clickedItem.INPUT_TYPE,
      },
      DROPDOWN_OPTIONS:
        clickedItem.DROPDOWN_OPTIONS == "" ? [] : clickedItem.DROPDOWN_OPTIONS,
    });

    let tempFieldDataSample = [...FieldDataSample];

    // if (key == "FIELD_TYPE") {
    if (clickedItem.FIELD_TYPE === "Dropdown") {
      const DropdownOptionIndex = tempFieldDataSample.findIndex(
        (item) => item.KEY == "DROPDOWN_OPTIONS"
      );
      const inputTypeIndex = tempFieldDataSample.findIndex(
        (item) => item.KEY == "INPUT_TYPE"
      );
      const maxLengthIndex = tempFieldDataSample.findIndex(
        (item) => item.KEY == "MAX_LENGTH"
      );
      tempFieldDataSample[DropdownOptionIndex].VISIBLE = true;
      tempFieldDataSample[maxLengthIndex].VISIBLE = false;
      tempFieldDataSample[inputTypeIndex].VISIBLE = false;
    } else if (clickedItem.FIELD_TYPE == "Input") {
      const DropdownOptionIndex = tempFieldDataSample.findIndex(
        (item) => item.KEY == "DROPDOWN_OPTIONS"
      );
      const inputTypeIndex = tempFieldDataSample.findIndex(
        (item) => item.KEY == "INPUT_TYPE"
      );
      const maxLengthIndex = tempFieldDataSample.findIndex(
        (item) => item.KEY == "MAX_LENGTH"
      );
      tempFieldDataSample[DropdownOptionIndex].VISIBLE = false;
      tempFieldDataSample[inputTypeIndex].VISIBLE = true;
      tempFieldDataSample[maxLengthIndex].VISIBLE = true;
    }

    // }

    setFieldDataSample(tempFieldDataSample);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFieldClickedData({});
    setTimeout(() => {
      setFieldDataSample([
        {
          KEY: "LABEL",
          TITLE: "Title",
          LOCAL_TYPE: "TEXT_FIELD",
          LOCAL_DATA_TYPE: "text",
          VISIBLE: true,
        },
        {
          KEY: "IS_REQUIRED",
          TITLE: "Required Field",
          LOCAL_TYPE: "DROPDOWN",
          LOCAL_DATA_TYPE: "text",
          VISIBLE: true,
          OPTIONS: [
            {
              label: "Yes",
              value: true,
            },
            {
              label: "No",
              value: false,
            },
          ],
        },

        {
          KEY: "FIELD_TYPE",
          TITLE: "Field type",
          LOCAL_TYPE: "DROPDOWN",
          LOCAL_DATA_TYPE: "text",
          VISIBLE: true,
          OPTIONS: [
            {
              label: "Input Box",
              value: "Input",
            },
            {
              label: "Dropdown",
              value: "Dropdown",
            },
          ],
          VALUE: {
            label: "Input Box",
            value: "Input",
          },
        },
        {
          KEY: "MAX_LENGTH",
          TITLE: "Text Max Length",
          LOCAL_TYPE: "TEXT_FIELD",
          LOCAL_DATA_TYPE: "text",
          VISIBLE: true,
        },
        {
          KEY: "INPUT_TYPE",
          TITLE: "Type",
          LOCAL_TYPE: "DROPDOWN",
          LOCAL_DATA_TYPE: "text",
          VISIBLE: true,
          OPTIONS: [
            {
              label: "text",
              value: "text",
            },

            {
              label: "number",
              value: "number",
            },
            {
              label: "file",
              value: "file",
            },
          ],
        },
        {
          KEY: "DROPDOWN_OPTIONS",
          TITLE: "Dropdown Options",
          LOCAL_DATA_TYPE: "file",
          LOCAL_TYPE: "TEXT_FIELD",
          VISIBLE: false,
          VALUE: [],
          FILE: [],
          FILE_NAME: "",
          // OPTIONS: [
          //   {
          //     label: "text",
          //     value: "text",
          //   },

          //   {
          //     label: "number",
          //     value: "number",
          //   },
          // ],
        },
      ]);
    }, 500);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleFieldValueChange = (mainIndex, subIndex, key, value) => {
    let tempState = [...state];
    tempState[mainIndex][subIndex][key] = value;

    setFieldClickedData((prev) => ({
      ...prev,
      [key]: value,
    }));

    setState(tempState);
  };
  const handleDropdownChange = (mainIndex, subIndex, key, value) => {
    let tempState = [...state];
    tempState[mainIndex][subIndex][key] = value.value;
    let tempFieldDataSample = [...FieldDataSample];
    if (key == "FIELD_TYPE") {
      if (value.value == "Dropdown") {
        const DropdownOptionIndex = tempFieldDataSample.findIndex(
          (item) => item.KEY == "DROPDOWN_OPTIONS"
        );
        const inputTypeIndex = tempFieldDataSample.findIndex(
          (item) => item.KEY == "INPUT_TYPE"
        );
        const maxLengthIndex = tempFieldDataSample.findIndex(
          (item) => item.KEY == "MAX_LENGTH"
        );
        tempFieldDataSample[DropdownOptionIndex].VISIBLE = true;
        tempFieldDataSample[maxLengthIndex].VISIBLE = false;
        tempFieldDataSample[inputTypeIndex].VISIBLE = false;
      } else if (value.value == "Input") {
        const DropdownOptionIndex = tempFieldDataSample.findIndex(
          (item) => item.KEY == "DROPDOWN_OPTIONS"
        );
        const inputTypeIndex = tempFieldDataSample.findIndex(
          (item) => item.KEY == "INPUT_TYPE"
        );
        const maxLengthIndex = tempFieldDataSample.findIndex(
          (item) => item.KEY == "MAX_LENGTH"
        );
        tempFieldDataSample[DropdownOptionIndex].VISIBLE = false;
        tempFieldDataSample[inputTypeIndex].VISIBLE = true;
        tempFieldDataSample[maxLengthIndex].VISIBLE = true;
      }
    }
    setFieldClickedData((prev) => ({
      ...prev,
      [key]: value,
    }));
    setFieldDataSample(tempFieldDataSample);

    setState(tempState);
  };

  const [FieldDataSample, setFieldDataSample] = useState([
    {
      KEY: "LABEL",
      TITLE: "Title",
      LOCAL_TYPE: "TEXT_FIELD",
      LOCAL_DATA_TYPE: "text",
      VISIBLE: true,
    },
    {
      KEY: "IS_REQUIRED",
      TITLE: "Required Field",
      LOCAL_TYPE: "DROPDOWN",
      LOCAL_DATA_TYPE: "text",
      VISIBLE: true,
      OPTIONS: [
        {
          label: "Yes",
          value: true,
        },
        {
          label: "No",
          value: false,
        },
      ],
    },

    {
      KEY: "FIELD_TYPE",
      TITLE: "Field type",
      LOCAL_TYPE: "DROPDOWN",
      LOCAL_DATA_TYPE: "text",
      VISIBLE: true,
      OPTIONS: [
        {
          label: "Input Box",
          value: "Input",
        },
        {
          label: "Dropdown",
          value: "Dropdown",
        },
      ],
      VALUE: {
        label: "Input Box",
        value: "Input",
      },
    },
    {
      KEY: "INPUT_TYPE",
      TITLE: "Type",
      LOCAL_TYPE: "DROPDOWN",
      LOCAL_DATA_TYPE: "text",
      VISIBLE: true,
      OPTIONS: [
        {
          label: "text",
          value: "text",
        },

        {
          label: "number",
          value: "number",
        },
        {
          label: "file",
          value: "file",
        },
      ],
    },
    {
      KEY: "MAX_LENGTH",
      TITLE: "Max Length",
      LOCAL_TYPE: "TEXT_FIELD",
      LOCAL_DATA_TYPE: "number",
      VISIBLE: false,
    },
    {
      KEY: "DROPDOWN_OPTIONS",
      TITLE: "Dropdown Options",
      LOCAL_DATA_TYPE: "file",
      LOCAL_TYPE: "TEXT_FIELD",
      VISIBLE: false,
      VALUE: [],
      FILE: [],
      FILE_NAME: "",
      // OPTIONS: [
      //   {
      //     label: "text",
      //     value: "text",
      //   },

      //   {
      //     label: "number",
      //     value: "number",
      //   },
      // ],
    },
  ]);
  useEffect(() => {
    if (fieldClickedData.DROPDOWN_OPTIONS == undefined) {
      // alert("No");
    }
  }, [FieldDataSample, fieldClickedData]);

  const handleFileUpload = (mainIndex, subIndex, key, e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        let tempJSON = [];
        json.map((val) => {
          if (val?.label == undefined || val?.value == undefined) {
            alert("Not a valid data");
          } else {
            tempJSON.push({
              label: val.label,
              value: val.value,
            });
          }
        });

        let tempState = [...state];
        tempState[mainIndex][subIndex][key] = tempJSON;

        let tempFieldDataSample = [...FieldDataSample];

        const dropDownOptionsIndex = tempFieldDataSample.findIndex(
          (item) => item.KEY == "DROPDOWN_OPTIONS"
        );

        tempFieldDataSample[dropDownOptionsIndex].VALUE = tempJSON;

        setFieldClickedData(tempFieldDataSample);
      };

      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const handleDropDownOptionDelete = (DeleteIndex, DeleteKey) => {
    let tempFieldDataSample = [...FieldDataSample];
    const DeleteKeyIndex = tempFieldDataSample.findIndex(
      (item) => item.KEY == DeleteKey
    );

    tempFieldDataSample[DeleteKeyIndex].VALUE.splice(DeleteIndex, 1);

    setFieldDataSample(tempFieldDataSample);
  };
  return (
    <MainScreen drawerWidth={282} ActiveKey={CONTROL_CENTER}>
      <Toaster />
      <div className="admin-control-center-container">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 15,
            backgroundColor: "#fff",
            padding: 5,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <div className="gray-arrow-container">Admin</div>
            <div className="blue-arrow-container">Control Center</div>
          </div>
          <button
            className="blue-button"
            onClick={() => {
              AssignFieldsToEmp();
            }}
          >
            Update List
          </button>
        </div>
        <div className="tab-creation-container">
          <Box
            sx={{
              mr: 1,
            }}
          >
            <CustomDropdown
              Options={ScreenList}
              Value={selectedModuleName}
              Label={"Screen"}
              OnChange={(e) => {
                setSelectedModuleName(e);
              }}
            />
          </Box>
          <Box
            sx={{
              mr: 1,
              display: "flex",
              alignItems: "center",
            }}
          >
            <input
              className="input-admin-center"
              placeholder="Enter Tab Name"
              onChange={(e) => {
                setNewTabName(e.target.value);
              }}
              disabled={false}
              value={newTabName}
              type="text"
              required={true}
            />
          </Box>
          <button
            className="blue-button"
            style={{
              fontSize: 20,
            }}
            onClick={() => {
              addTab();
            }}
          >
            +
          </button>
        </div>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              // justifyContent:"center"
              // margin: 10,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <CustomDropdown
                Options={ScreenList}
                Value={selectedModuleName}
                // Disabled={GeneralDataDisableFlags?.BUSINESS_ROLE_DISABLE_FLAG}
                // error={GeneralDataErrorFlags?.BUSINESS_ROLE_ERROR}
                Label={"Screen"}
                OnChange={(e) => {
                  setSelectedModuleName(e);
                }}
              />
            </Box>

            <CustomInput
              Placeholder="Tab Name"
              onChange={(e) => {
                setNewTabName(e.target.value);
              }}
              Disabled={false}
              value={newTabName}
              Type="text"
              isRequired={true}
            />

          </div>
          <Button
            variant="outlined"
            onClick={() => {
              // setState([...state, []]);
              addTab();
            }}
          >
            Add Tab
          </Button>

          <Button
            variant="outlined"
            onClick={() => {
              // setState([...state, []]);
              AssignFieldsToEmp();
            }}
          >
            Update List
          </Button>
 
        </div> */}

        <div className="sap-fields-list">
          <DragDropContext onDragEnd={onDragEnd}>
            {state.map((el, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={
                {      ...(getListStyle(snapshot.isDraggingOver),
                      getItemStyle(snapshot.isDraggingOver)),
                    marginLeft:10
                    }
                    }
                    {...provided.droppableProps}
                    className="list-container"
               
                  >
                    <div
                      className="droppable-container list-container"
                      style={
                        {
                          // backgroundColor: "red",
                          // border: 1,
                          // borderColor: "red",
                        }
                      }
                      // className="list-container"
                    >
                      {el.map((item, index) => {
                        return (
                          <div
                            style={
                              {
                                // backgroundColor:"red"
                              }
                            }
                          >
                            {index == 0 && (
                              <p
                                style={{
                                  backgroundColor: "#22aad2",
                                  color: "#fff",
                                  padding: 10,
                                  borderRadius: 4,
                                  position: "sticky",
                                  top: 0,
                                }}
                              >
                                {item.CATEGORY}
                              </p>
                            )}

                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  // style={getItemStyle()
                                  // snapshot.isDragging,
                                  // provided.draggableProps.style
                                  // }
                                  // style={{
                                  //   backgroundColor:"red"
                                  // }}
                                  className=""
                                  // style={{
                                  //   display:"flex",
                                  //   justifyContent:"space-between",
                                  //   alignContent:"center",
                                  //   alignItems:"center"

                                  // }}
                                >
                                  {/* <div className="droppable-item"> */}
                                  {item.LABEL != undefined && (
                                    <Card
                                      sx={{
                                        m: 1,
                                        p: 1,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                          }}
                                        >
                                          <IconButton
                                            Icon={Icons.Draggable}
                                            style={{
                                              height: 20,
                                              width: 20,
                                            }}
                                            onClick={(e) => {
                                              // handleClick(e, item, ind, index);
                                            }}
                                            aria-describedby={id}
                                          />
                                          <div className="draggable-item-text">
                                            {item.LABEL}
                                          </div>
                                        </div>

                                        {/* <CustomLabel LabelText={item.LABEL} /> */}
                                        <IconButton
                                          Icon={Icons.Edit}
                                          style={{
                                            height: 20,
                                            width: 20,
                                          }}
                                          onClick={(e) => {
                                            handleClick(e, item, ind, index);
                                          }}
                                          aria-describedby={id}
                                        />
                                      </div>
                                    </Card>
                                  )}

                                  <div />
                                </div>
                              )}
                            </Draggable>
                          </div>
                        );
                      })}
                    </div>

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 2, flexDirection: "column" }}>
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: 18,
              }}
            >
              Details
            </Typography>
            <Divider />
            {/* {fieldClickedData?.LABEL!=undefined &&÷ } */}
            {FieldDataSample.map((val) => {
              return (
                <Box
                  sx={{
                    p: 1,
                  }}
                >
                  <Box>
                    {val.LOCAL_TYPE == "TEXT_FIELD" && val.VISIBLE == true && (
                      <>
                        <p>{val.TITLE}</p>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={
                            val.LOCAL_DATA_TYPE == "file"
                              ? ""
                              : fieldClickedData[val.KEY]
                          }
                          style={{
                            width: "100%",
                          }}
                          accept="*"
                          type={val.LOCAL_DATA_TYPE}
                          // label={val.LOCAL_DATA_TYPE=="file"?"": val.TITLE}
                          onChange={(e) => {
                            if (val.LOCAL_DATA_TYPE == "file") {
                              handleFileUpload(
                                fieldClickedData.MAIN_INDEX,
                                fieldClickedData.SUB_INDEX,
                                val.KEY,
                                e
                              );
                            } else {
                              handleFieldValueChange(
                                fieldClickedData.MAIN_INDEX,
                                fieldClickedData.SUB_INDEX,
                                val.KEY,
                                e.target.value
                              );
                            }
                          }}
                        />
                      </>
                    )}

                    {val.LOCAL_TYPE == "DROPDOWN" && val.VISIBLE == true && (
                      // <CustomDropdown
                      //   Options={val.OPTIONS}
                      // />
                      <>
                        <p>{val.TITLE}</p>
                        <CustomDropdown
                          Options={val.OPTIONS}
                          Value={fieldClickedData[val.KEY]}
                          // Disabled={GeneralDataDisableFlags?.BUSINESS_ROLE_DISABLE_FLAG}
                          // error={GeneralDataErrorFlags?.BUSINESS_ROLE_ERROR}
                          Label={val.TITLE}
                          OnChange={(e) => {
                            handleDropdownChange(
                              fieldClickedData.MAIN_INDEX,
                              fieldClickedData.SUB_INDEX,
                              val.KEY,
                              e
                            );
                          }}
                        />
                      </>
                    )}
                    {val.KEY == "DROPDOWN_OPTIONS" && (
                      <div>
                        {val.VALUE.length > 0 && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p>Label</p>
                            <p>Value</p>
                            <p>Action</p>
                          </div>
                        )}
                        {fieldClickedData?.DROPDOWN_OPTIONS != undefined &&
                          fieldClickedData.DROPDOWN_OPTIONS.map(
                            (valueItem, valueItemIndex) => {
                              // alert("yes")
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <td>{valueItem?.label}</td>
                                  <td>{valueItem?.value}</td>
                                  <td>
                                    <IconButton
                                      Icon={Icons.DeleteRed}
                                      style={{
                                        height: 20,
                                        width: 20,
                                      }}
                                      onClick={() => {
                                        handleDropDownOptionDelete(
                                          valueItemIndex,
                                          val.KEY
                                        );
                                      }}
                                    />
                                  </td>
                                </div>
                              );
                            }
                          )}
                      </div>
                    )}
                  </Box>
                </Box>
              );
            })}
            <Button onClick={handleClose}>Save</Button>
          </Box>
        </Popover>
      </div>
    </MainScreen>
  );
}
export default AdminControlCenter;
// const rootElement = document.getElementById("root");
// ReactDOM.render(<QuoteApp />, rootElement);
