import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import AdminLogin from "./screens/Admin/AdminLogin";
import AdminControlCenter from "./screens/Admin/AdminControlCenter";
import CustomerCreation from "./screens/Customer/CustomerCreation";
// import ApprovalCenter from "./screens/Admin/ApprovalCenter";
import CustomerApproval from "./screens/Customer/CustomerApproval";
import {
  APPROVAL_CENTER,
  CONTROL_CENTER,
  CUSTOMER,
  CUSTOMER_APPROVAL,
  CUSTOMER_CREATION,
  CUSTOMER_EMAIL_CREATION,
  ROLES_PERMISSION,
} from "./screens/Utils/Routes";
import BusinessCardScanner from "./screens/Customer/ImageScanner";
import Login from "./screens/Login/Login";
import ApprovalCenter from "./screens/ApprovalCenter/ApprovalCenter";
import CustomerSap from "./screens/CustomerSap/CustomerSap";
import Test from "./Test";
import RolePermission from "./screens/Admin/RolePermission";
import { useEffect } from "react";
import AXIOS from "./screens/Utils/AXIOS";
import axios from "axios";
import { connect } from "react-redux";
import { LoginAction } from "./redux/action/LoginAction";
import CustomerEmailCreation from "./screens/Customer/CustomerEmailCreation";

function App(props) {
  useEffect(() => {
    let session_token = localStorage.getItem("MDM_TOKEN");
    if (session_token) {
      console.log(
        "asasdasdas",
        AXIOS.axiosUrl + AXIOS.login_session_get + session_token
      );
      axios
        .get(AXIOS.axiosUrl + AXIOS.login_session_get + session_token)
        .then((response) => {
          console.log("Dasdasdasd", response.data[0]);
          if (response.data[0].IS_LOGOUT == false) {
            props.LoginAction({ ...response.data[0] });
            // navigate(CUSTOMER_CREATION);
          }
        });
    }
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path={CONTROL_CENTER} element={<AdminControlCenter />} />
        <Route path={CUSTOMER_CREATION} element={<CustomerCreation />} />
        <Route path={APPROVAL_CENTER} element={<ApprovalCenter />} />
        <Route path={CUSTOMER_APPROVAL} element={<CustomerApproval />} />
        <Route path={CUSTOMER} element={<CustomerSap />} />
        <Route path={ROLES_PERMISSION} element={<RolePermission />} />
        <Route path={CUSTOMER_EMAIL_CREATION} element={<CustomerEmailCreation />} />
        {/* <Route path="image" element={<BusinessCardScanner />} /> */}
        <Route path="test" element={<Test />} />
      </Routes>
    </BrowserRouter>
  );
}

// export default App;

// export default Login;
const mapStateToProps = (state) => ({
  CUSTOMER_DATA: state.customer.customer_data,
});

export default connect(mapStateToProps, { LoginAction })(App);
