const initialState = {
  customer_data: [],

};

const auth = (state = initialState, action) => {
  switch (action.type) {
 
    case "SET_CUSTOMER_DATA":
      return {
        ...state,
        customer_data: action.customer_data,
      };
    default:
      return state;
  }
};

export default auth;
