import React, { useEffect } from 'react'
import myAxios from '../Utils/CustomAxios';

function ImageScanner() {
    useEffect(() => {
      // Example GET request
      myAxios
        .get("https://jsonplaceholder.typicode.com/posts/1")
        .then((response) => {
          console.log("GET Response:", response);
        })
        .catch((error) => {
          console.error("GET Error:", error.message);
        });

      // Example POST request
      myAxios
        .post("https://jsonplaceholder.typicode.com/posts", {
          title: "foo",
          body: "bar",
          userId: 1,
        })
        .then((response) => {
          console.log("POST Response:", response);
        })
        .catch((error) => {
          console.error("POST Error:", error.message);
        });
    }, []);
  return (
    <div>ImageScanner</div>
  )
}

export default ImageScanner