import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import CurrencyBitcoinRoundedIcon from "@mui/icons-material/CurrencyBitcoinRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import AddIcon from "@mui/icons-material/Add";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  Avatar,
  Badge,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import {
//   CUSTOMER,
//   CUSTOMER_CREATION,
//   DASHBOARD,
//   VENDOR,
//   VENDOR_APPROVAL,
//   VENDOR_CREATION,
// } from "../../Utils/Routes";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../screens/Utils/Theme";
import axios from "axios";
import AXIOS from "../../screens/Utils/AXIOS";
import {
  APPROVAL_CENTER,
  CONTROL_CENTER,
  CUSTOMER,
  CUSTOMER_APPROVAL,
  CUSTOMER_CREATION,
  DASHBOARD,
  VENDOR,
  VENDOR_APPROVAL,
  VENDOR_CREATION,
} from "../../screens/Utils/Routes";
import Icons from "../../assets/Icons";
import { ContentCut } from "@mui/icons-material";
import { connect } from "react-redux";
// import AXIOS from "../../Utils/AXIOS";
// import ICONS from "../../utils/ICONS";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};
function Header(props, { children }) {
  const logoutSession = (SessionType) => {
    axios
      .post(AXIOS.axiosUrl + AXIOS.login_session_logout, {
        SESSION_ID: props.AUTH_DATA.SESSION_ID,
        SESSION_TYPE: SessionType,
        // USER_ID: props.AUTH_ID,
      })
      .then((response) => {
        localStorage.removeItem("MDM_TOKEN");
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        localStorage.removeItem("MDM_TOKEN");
        navigate("/");
      });
  };
  const vendorDropDownOptions = [
    {
      label: "Vendor",
      icon: <GroupOutlinedIcon />,
      path: VENDOR,
    },
    {
      label: "Vendor Approval",
      icon: <FactCheckIcon />,
      path: VENDOR_APPROVAL,
    },
    {
      label: "Vendor Creation",
      icon: <AddBoxIcon />,
      path: VENDOR_CREATION,
    },
  ];
  const CustomerDropDownOptions = [
    {
      label: "Customer",
      icon: <GroupOutlinedIcon />,
      path: CUSTOMER,
    },
    {
      label: "Customer Creation",
      icon: <FactCheckIcon />,
      path: CUSTOMER_CREATION,
    },
    {
      label: "Customer Approval",
      icon: <FactCheckIcon />,
      path: CUSTOMER_APPROVAL,
    },
  ];
  const AdminDropDownOptions = [
    {
      label: "Control Center",
      icon: <GroupOutlinedIcon />,
      path: CONTROL_CENTER,
    },
    {
      label: "Approver Center",
      icon: <FactCheckIcon />,
      path: APPROVAL_CENTER,
    },
  ];
  // const [anchorEl, setAnchorEl] =
  //   (React.useState < null) | (HTMLElement > null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const [vendorAnchorEL, setVendorAnchorEL] = React.useState(null);
  const openVendorDropdown = Boolean(vendorAnchorEL);
  const handleVendorDropdownOpen = (event) => {
    setVendorAnchorEL(event.currentTarget);
  };
  const handleVendorDropdownClose = (path) => {
    setVendorAnchorEL(null);
  };
  const [customerAnchorEL, setCustomerAnchorEL] = React.useState(null);
  const openCustomerDropdown = Boolean(customerAnchorEL);
  const handleCustomerDropdownOpen = (event) => {
    console.log("eventashajsd", event);
    setCustomerAnchorEL(event.currentTarget);
  };
  const handleCustomerDropdownClose = (path) => {
    setCustomerAnchorEL(null);
  };
  const [AdminAnchorEL, setAdminAnchorEL] = React.useState(null);
  const openAdminDropdown = Boolean(AdminAnchorEL);
  const handleAdminDropdownOpen = (event) => {
    console.log("eventashajsd", event);
    setAdminAnchorEL(event.currentTarget);
  };
  const handleAdminDropdownClose = (path) => {
    setAdminAnchorEL(null);
  };
  const handleDrawerToggle = () => {
    console.log("ASdasdasd", !props.showDrawer);
    props.toggleDrawer(!props.showDrawer);
  };
  const breakPointlg = useMediaQuery(useTheme().breakpoints.up("lg"));

  // const handleLogout = () => {
  //   axios.post(AXIOS.axiosUrl + AXIOS.login_session_logout, {
  //     SESSION_ID: "",
  //     SESSION_TYPE: "SINGLE",
  //   });
  // };

  const [userName,setUserName]=React.useState("")
  React.useEffect(() => {
    setUserName(props.AUTH_DATA?.USER_NAME);
  }, [props]);
  return (
    <Box sx={{ width: "100%" }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar variant="dense">
          {/* <Typography variant="h6" color="inherit" component="div">
            Photos
          </Typography> */}
          {!breakPointlg && (
            <IconButton>
              <img
                src={Icons.Hamburger_icon_black}
                style={{
                  width: 24,
                  height: 16,
                }}
                onClick={() => {
                  handleDrawerToggle();
                }}
              />
            </IconButton>
          )}
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <IconButton
              aria-label="show notification"
              size="large"
              color="inherit"
              sx={{ mr: 2 }}
              className="nav-bar-item"
            >
              <Badge badgeContent={4} color="error">
                <NotificationsNoneRoundedIcon
                  sx={{
                    color: COLORS.gray90,
                  }}
                />
              </Badge>
            </IconButton>
            {/* <IconButton
              aria-label="show notification"
              size="large"
              color="inherit"
              sx={{ mr: 2 }}
              className="nav-bar-item"
            >
         

              <img src={Icons.profile_img} />
            </IconButton>{" "} */}
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
              >
                {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
                <img src={Icons.profile_img} />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={handleClose}>
                <Avatar />
                Profile {userName}
              </MenuItem>
              <Divider />

              <MenuItem
                onClick={() => {
                  logoutSession("SINGLE");
                }}
              >
                <LogoutIcon />
                Logout
              </MenuItem>
              {/* <Divider /> */}
            </Menu>
            {/* <Avatar
                alt="Cindy Baker"
                src={ICONS.ProfileIcon}
                className="nav-bar-item"
                style={{
                  cursor: "pointer",
                }}
              /> */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  AUTH_DATA: state.auth.auth_data,
  IS_AUTH: state.auth.is_auth,
});

export default connect(mapStateToProps)(Header);
