import React from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  useMediaQuery,
  useTheme,
  Box,
  Divider,
} from "@mui/material";

import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import CurrencyBitcoinRoundedIcon from "@mui/icons-material/CurrencyBitcoinRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import AddBoxIcon from "@mui/icons-material/AddBox";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import { ACCOUNT, CUSTOMER, LOGIN } from "../util/Routes";
import { useNavigate } from "react-router-dom";
import {
  APPROVAL_CENTER,
  CONTROL_CENTER,
  CUSTOMER,
  CUSTOMER_APPROVAL,
  CUSTOMER_CREATION,
  DASHBOARD,
  PROFILE,
  VENDOR,
  VENDOR_APPROVAL,
  VENDOR_CREATION,
} from "../../screens/Utils/Routes";
// import { COLORS } from "../../utils/Theme";
import "./styles.css";
import IMAGES from "../../assets/IMAGES";
import Icons from "../../assets/Icons";
import Collapsible from "react-collapsible";
const RoutesList = [
  {
    label: "Dashboard",
    icon: <HomeRoundedIcon />,
    path: DASHBOARD,
    SUB_ROUTES: [],
  },
  // {
  //   label: "Vendor",
  //   icon: <PersonAddIcon />,
  //   path: VENDOR,
  //   SUB_ROUTES: [
  //     {
  //       sub_label: "Vendor",
  //       sub_icon: <GroupOutlinedIcon />,
  //       sub_path: VENDOR,
  //     },
  //     {
  //       sub_label: "Vendor Approval",
  //       sub_icon: <FactCheckIcon />,
  //       sub_path: VENDOR_APPROVAL,
  //     },
  //     {
  //       sub_label: "Vendor Creation",
  //       sub_icon: <AddBoxIcon />,
  //       sub_path: VENDOR_CREATION,
  //     },
  //   ],
  // },
  {
    label: "Customer",
    icon: <GroupsIcon />,
    // path: ,
    SUB_ROUTES: [
      {
        sub_label: "Customers",
        sub_icon: <GroupOutlinedIcon />,
        sub_path: CUSTOMER,
      },
      {
        sub_label: "Customer Creation",
        sub_icon: <AddBoxIcon />,

        sub_path: CUSTOMER_CREATION,
      },
      {
        sub_label: "Customer Approval",
        sub_icon: <FactCheckIcon />,

        sub_path: CUSTOMER_APPROVAL,
      },
    ],
  },
  {
    label: "Admin",
    icon: <AdminPanelSettingsIcon />,
    // path: ,
    SUB_ROUTES: [
      {
        sub_label: "Admin Center",
        sub_icon: <GroupOutlinedIcon />,
        sub_path: CONTROL_CENTER,
      },
      {
        sub_label: "Approval Center",
        sub_icon: <FactCheckIcon />,
        sub_path: APPROVAL_CENTER,
      },
    ],
  },
];

export default function AppDrawer(props) {
  const navigate = useNavigate();
  const breakPointlg = useMediaQuery(useTheme().breakpoints.up("lg"));

  const handleDrawerToggle = () => {
    console.log("ASdasdasd", !props.showDrawer);
    props.toggleDrawer(!props.showDrawer);
  };

  return (
    <Box
      sx={{
        backgroundColor: "red",
      }}
    >
      <Drawer
        open={props.showDrawer}
        variant={`${breakPointlg ? "permanent" : "temporary"}`}
        ModalProps={{
          keepMounted: true,
        }}
        onClose={handleDrawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            zIndex: (theme) => theme.zIndex.drawer - 200,
            boxSizing: "border-box",
            width: props.drawerWidth,
            backgroundColor: "#001217",
          },
        }}
      >
        <div className="logo-container">
          <img
            src={IMAGES.samishti_logo}
            style={{
              width: 53,
              height: 50,
            }}
          />
          {!breakPointlg && (
            <img
              src={Icons.Hamburger_icon}
              style={{
                width: 24,
                height: 16,
              }}
              onClick={() => {
                handleDrawerToggle();
              }}
            />
          )}
        </div>

        <div className="back-ground-drawer">
          {/* <>
            <aside>
              <p> Menu </p>
              <a href="javascript:void(0)">
                <i className="fa fa-user-o" aria-hidden="true" />
                My drive
              </a>
              <a href="javascript:void(0)">sdas</a>
              <a href="javascript:void(0)">
                <i className="fa fa-clone" aria-hidden="true" />
                Shared with me
              </a>
              <a href="javascript:void(0)">
                <i className="fa fa-star-o" aria-hidden="true" />
                Starred
              </a>
              <a href="javascript:void(0)">
                <i className="fa fa-trash-o" aria-hidden="true" />
                Trash
              </a>
            </aside>
            <div className="social">
              <a
                href="https://www.linkedin.com/in/florin-cornea-b5118057/"
                target="_blank"
              >
                <i className="fa fa-linkedin" />
              </a>
            </div>
          </> */}
          <List sx={{ width: 1 }}>
            {RoutesList.map((val) => {
              return (
                <>
                  {val.SUB_ROUTES.length <= 0 && (
                    <div
                      className="drawer-item"
                      onClick={() => {
                        navigate(val.path);
                      }}
                    >
                      {val.icon}
                      {val.label}
                    </div>
                  )}
                  {val.SUB_ROUTES.length > 0 && (
                    <>
                      <Collapsible
                        trigger={
                          <div className="drawer-item-collapsible-container">
                            <div className="drawer-item">
                              {val.icon}
                              {val.label}
                            </div>
                            <KeyboardArrowUpIcon sx={{ color: "#fff" }} />
                          </div>
                        }
                        onTriggerOpening={() => {
                          // alert("asd");
                        }}
                      >
                        <div className="drawer-sub-container">
                          {val.SUB_ROUTES.map((innerVal) => {
                            return (
                              <div
                                className="drawer-sub-item active"
                                onClick={() => {
                                  navigate(innerVal.sub_path);
                                }}
                                style={{
                                  color:
                                    props.ActiveKey == innerVal.sub_path
                                      ? "#22AAD2"
                                      : "#515151",
                                  fontWeight:
                                    props.ActiveKey == innerVal.sub_path
                                      ? "bold"
                                      : "",
                                  cursor: "pointer",
                                }}
                              >
                                {innerVal.sub_icon}

                                {innerVal.sub_label}
                              </div>
                            );
                          })}
                        </div>
                      </Collapsible>
                    </>
                  )}
                </>
              );
            })}
          </List>

          {/* <List sx={{ width: 1 }}>
            {RoutesList.map((navItem, index) => {
              return (
                <>
                  <ListItem
                    key={navItem.label}
                    disablePadding
                    sx={{
                      borderRadius: 4,
                      overflow: "hidden",
                      backgroundColor:
                        props.ActiveKey == navItem.path ? "#141a30" : null,
                      color: props.ActiveKey == navItem.path ? "#fff" : null,
                    }}
                    onClick={(e) => {
                      navigate(navItem.path, {
                        state: {
                          path: navItem.path,
                        },
                      });
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon
                        style={{
                          backgroundColor: "white",
                        }}
                      >
                        {navItem.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={navItem.label}
                        sx={{ color: "white" }}
                      />
                    </ListItemButton>
                  </ListItem>
                  <Divider />
                </>
              );
            })}
          </List> */}
        </div>
      </Drawer>
    </Box>
  );
}
