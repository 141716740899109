
import React, { useRef } from 'react';
 
function Test() {
  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
 
  const handleChange = (index, e) => {
    const value = e.target.value;
    // If a letter is typed and the current input is not the last one
    if (value.length === 1 && index < inputRefs.length - 1) {
      // Focus on the next input
      inputRefs[index + 1].current.focus();
    }
    // If backspace is pressed and the current input is not the first one
    else if (value.length === 0 && index > 0) {
      // Focus on the previous input
      inputRefs[index - 1].current.focus();
    }
  };
 
  return (
<div>
      {inputRefs.map((ref, index) => (
<input
          key={index}
          ref={ref}
          maxLength={1}
          style={{ width: '30px', height: '30px', marginRight: '5px' }}
          onChange={(e) => handleChange(index, e)}
        />
      ))}
</div>
  );
}
 
export default Test;