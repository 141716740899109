import React, { useEffect, useState } from "react";
import "./Login.css";
import CustomInput from "../../components/CustomInput/CustomInput";
import { Typography } from "@mui/material";
import { CheckBox } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_CREATION } from "../Utils/Routes";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import AXIOS from "../Utils/AXIOS";
import platform from "platform";
import { connect } from "react-redux";
import { LoginAction } from "../../redux/action/LoginAction";

function Login(props) {
  useEffect(() => {
    let session_token = localStorage.getItem("MDM_TOKEN");
    if (session_token) {
      console.log(
        "asasdasdas",
        AXIOS.axiosUrl + AXIOS.login_session_get + session_token
      );
      axios
        .get(AXIOS.axiosUrl + AXIOS.login_session_get + session_token)
        .then((response) => {
          console.log("Dasdasdasd", response.data);
          if (response.data[0].IS_LOGOUT == false) {
            props.LoginAction({ ...response.data[0] });
            navigate(CUSTOMER_CREATION);
          }
        });
    }
  }, []);
  const navigate = useNavigate();

  const [userCredentials, setUserCredentials] = useState({
    USERNAME: "",
    USERNAME_ERROR_FLAG: false,
    PASSWORD: "",
    PASSWORD_ERROR_FLAG: false,
    IP_ADDRESS: "",
    DEVICE: navigator.userAgent,
  });

  useEffect(() => {
    axios
      .get("https://api.ipify.org?format=json")

      .then((response) => {
        console.log("ASdasd", response.data);
        setUserCredentials((prev) => ({
          ...prev,
          IP_ADDRESS: response.data.ip,
        }));
      })
      .catch((error) => console.log(error));
  }, []);

  console.log("SAdasdas", navigator.userAgent);
  const handleLogin = () => {
    if (userCredentials.USERNAME == "") {
      toast.error("Please fill username");
      // setUserCredentials
      setUserCredentials((prev) => ({
        ...prev,
        USERNAME_ERROR_FLAG: true,
      }));
    } else if (userCredentials.PASSWORD == "") {
      setUserCredentials((prev) => ({
        ...prev,
        PASSWORD_ERROR_FLAG: true,
      }));
      toast.error("Please fill Password");
    } else if (
      userCredentials.USERNAME != "" &&
      userCredentials.PASSWORD != ""
    ) {
      axios
        .post(
          AXIOS.axiosUrl + AXIOS.login_session_auth,

          {
            USER_ID: userCredentials.USERNAME,
            PASSWORD: userCredentials.PASSWORD,
            IP_ADDRESS: userCredentials.IP_ADDRESS,
            USER_DEVICE: userCredentials.DEVICE,
            LAST_ACTIVITY: new Date(),
          }
        )
        .then((response) => {
          console.log("ashdbajsdas", response.data?.SESSION_ID);
          if (response.data?.SESSION_ID) {
            localStorage.setItem("MDM_TOKEN", response.data.SESSION_ID);
            props.LoginAction({
              ...response.data.data,
              SESSION_ID: response.data.SESSION_ID,
            });
            navigate(CUSTOMER_CREATION);
          }else{
            toast.error("ID or password is wrong")
          }

          console.log("SAasdasd", response.data);
          // return response.data;
        })
        .catch((err) => {
          console.log("dasdasd", err);
        });
    }
    // let data = props.LoginAction(userCredentials);
    console.log("ASdasdasdasdsadasd");
  };

  return (
    <div className="login-container">
      <Toaster/>
      <div className="login-left-container">
        <div className="login-left-inner-container">
          <h2 className="header-text inter-font"> Hello! Welcome Back</h2>
          <p className="sub-heading-text inter-font">
            Enter your details here to sign in
          </p>
          <div className="form-container">
            <p className="input-box-title">Email Address</p>
            <CustomInput
              Placeholder={"Enter your email address"}
              onChange={(e) => {
                setUserCredentials((prev) => ({
                  ...prev,
                  USERNAME: e.target.value,
                }));
              }}
            />
            <p className="input-box-title">password</p>
            <CustomInput
              Placeholder={"Password"}
              onChange={(e) => {
                setUserCredentials((prev) => ({
                  ...prev,
                  PASSWORD: e.target.value,
                }));
              }}
              Type="password"
            />
            <div className="forgot-password-container">
              <p className="forgot-password-text">forgot password?</p>
            </div>
            <button
              className="signup-button"
              onClick={() => {
                // navigate(CUSTOMER_CREATION);
                handleLogin();
              }}
            >
              <span>Sign In</span>
            </button>
          </div>
        </div>
      </div>
      <div className="login-right-container cover-img"></div>
    </div>
  );
}

// export default Login;
const mapStateToProps = (state) => ({
  CUSTOMER_DATA: state.customer.customer_data,
});

export default connect(mapStateToProps, { LoginAction })(Login);
