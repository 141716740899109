import {
  Button,
  CssBaseline,
  Tab,
  Card,
  Tabs,
  Tooltip,
  Typography,
  Modal,
} from "@mui/material";
import { Box, height } from "@mui/system";
import React, { useEffect } from "react";
import Header from "../../components/AppDrawer/Header";
import ImageIcon from "../../components/ImageIcon/ImageIcon";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import MainScreen from "../../components/AppDrawer/MainScreen";
import IMAGES from "../../assets/IMAGES";
import "./style.css";
import axios from "axios";
import AXIOS from "../Utils/AXIOS";
import CustomLabel from "../../components/CustomLabel/CustomLabel";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomInput from "../../components/CustomInput/CustomInput";
import { COLORS } from "../Utils/Theme";
import { connect } from "react-redux";
import { setCustomerDataAction } from "../../redux/action/customerDataAction";
import toast, { Toaster } from "react-hot-toast";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomerInputBox from "../../components/CustomerInputBox/CustomerInputBox";
import { CUSTOMER_CREATION } from "../Utils/Routes";
import { useParams } from "react-router-dom";

const drawerWidth = 282;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "100%",
  // height:"100vh",
  bgcolor: "#fff",
  // border: '0px solid #fff',
  // boxShadow: 24,
};

function CustomerEmailCreation(props) {

  const [ShowActionButton,setShowActionButton]=useState(true)
  const requiredFieldsId = ["NAME1", "SMTP_ADDR"];
  const [count, setCount] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      } else {
        clearInterval(timer);
      }
    }, 1000); // Update the timer every second

    return () => {
      clearInterval(timer); // Cleanup the timer when the component unmounts
    };
  }, [count]);

  let [searchParams, setSearchParams] = useSearchParams();

  const [userInfo, setUserInfo] = useState({
    APPLICATION_ID: null,
    EMAIL_ID: null,
  });
  useEffect(() => {
    let param_application_id = searchParams.get("APPLICATION_ID");
    let param_email_id = searchParams.get("EU_TOKEN");
    if (param_application_id != undefined && param_email_id) {
      param_application_id = atob(param_application_id);
      param_email_id = atob(param_email_id);
      console.log("ASdadsad", param_email_id, param_application_id);
      setUserInfo({
        APPLICATION_ID: param_application_id,
        EMAIL_ID: param_email_id,
      });
    }else{
      setShowActionButton(false)
    }
  }, [searchParams]);

  
useEffect(() => {

}, [userInfo]);


  const sendOTPtoCustomer = () => {};

  const navigate = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);
  const [VendorTab, setVendorTab] = useState(1);
  const [showDrawerAppBar, setShowDrawerAppBar] = useState(null);
  const [allCategory, setAllCategory] = useState([]);
  const [currentApplicationData, setCurrentApplicationData] = useState([]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Cancel the event as newer browsers will ignore custom messages
      event.preventDefault();
      // Chrome requires returnValue to be set
      event.returnValue = "";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const [OTPModalFlag, setOTPModalFlag] = useState(false);
  const handleOTPModalOpen = () => setOTPModalFlag(true);
  const handleOTPModalClose = () => setOTPModalFlag(false);

  const handleSelectedLoginTab = (event, newValue) => {
    setVendorTab(newValue);
  };

  useEffect(() => {
    if (userInfo.APPLICATION_ID) {
      // getCurrentApplicationDetail();
      getCustomerCreationFields();
    }
  }, [userInfo]);

  const getCurrentApplicationDetail = (FieldsData) => {
    axios
      .get(
        AXIOS.axiosUrl +
          AXIOS.customer_get_by_application_id +
          userInfo.APPLICATION_ID
      )
      .then((response) => {
        console.log("ASdkashdbjsad", response.data);

        setCurrentApplicationData(response.data[0]);
        let applicationSubmittedFlag = false;

        if (response.data[0]?.APPLICATION_SUBMITTED == true) {
          applicationSubmittedFlag = true;
          setShowActionButton(false);
        }

        if (applicationSubmittedFlag == true) {
          FieldsData.map((val) => {
            val.FIELDS.map((item) => {
              item.IS_DISABLED = true;
            });
          });
        }

        let tempResponse = [];

        let responseData = [];

        Object.keys(response.data[0]).forEach(function (key, index, value) {
          responseData.push({
            FIELD_ID: key,
            VALUE: response.data[0][key],
          });
        });

        let tempFieldsData = [];
        FieldsData.map((val) => {
          val.FIELDS.map((item) => {
            tempFieldsData.push({ ...item });
          });
        });

        responseData.map((val) => {
          let data;
          tempFieldsData.map((item) => {
            if (item.FIELD_TYPE == "Dropdown") {
              data = item.DROPDOWN_OPTIONS.find(
                (options) => options.value === val.VALUE
              );

              console.log("asdkasdhasd",data);
              if (data) {
                val.VALUE = data;
              }
            }
          });
        });

        responseData.map((val) => {
          FieldsData.map((item) => {
            const innerIndex = item.FIELDS.findIndex(
              (innerVal) => innerVal.FIELD_ID == val.FIELD_ID
            );
            if (innerIndex != -1) {
              item.FIELDS[innerIndex].VALUE = val.VALUE;
            }
          });
        });

        setAllCategory(FieldsData);

        let tempReduxCustomerData = [...props.CUSTOMER_DATA];

        responseData.map((val) => {
          const foundIndexInRedux = tempReduxCustomerData.findIndex(
            (item) => item.FIELD_ID == val.FIELD_ID
          );
          if (foundIndexInRedux == -1) {
            tempReduxCustomerData.push({ ...val });
          } else {
            tempReduxCustomerData[foundIndexInRedux] = { ...val };
          }
        });

        props.setCustomerDataAction(tempReduxCustomerData);
      });
  };

  const getCustomerCreationFields = () => {
    axios
      .get(AXIOS.axiosUrl + AXIOS.customer_field_get)
      .then((response) => {
        let tempCategoryArr = [];
        response.data.map((val) => {
          let categoryIndex = tempCategoryArr.findIndex(
            (item) => val.CATEGORY_ID == item.CATEGORY_ID
          );
          let dropDownData = [];
          if (val.DROPDOWN_OPTIONS == "") {
            dropDownData = val.DROPDOWN_OPTIONS;
          } else {
            val.DROPDOWN_OPTIONS.map((val) => {
              dropDownData.push({ ...val });
            });
          }

          const FieldRequire = requiredFieldsId.includes(val.FIELD_ID);
          if (categoryIndex == -1) {
            tempCategoryArr.push({
              CATEGORY_ID: val.CATEGORY_ID,
              CATEGORY: val.CATEGORY,
              FIELDS: [
                {
                  ...val,
                  VALUE: "",
                  FILE: [],
                  DROPDOWN_OPTIONS: dropDownData,
                  IS_REQUIRED: FieldRequire,
                },
              ],
            });
          } else {
            tempCategoryArr[categoryIndex].FIELDS.push({
              ...val,
              VALUE: "",
              FILE: [],
              DROPDOWN_OPTIONS: dropDownData,
              IS_REQUIRED: FieldRequire,
            });
          }
        });
        getCurrentApplicationDetail(tempCategoryArr);
        setAllCategory(tempCategoryArr);
        setVendorTab(tempCategoryArr[0].CATEGORY_ID);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [selectedData, setSelectedData] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  useEffect(() => {
    const tempSelectedData = allCategory.find(
      (val) => val.CATEGORY_ID == VendorTab
    );
    const tempSelectedDataIndex = allCategory.findIndex(
      (val) => val.CATEGORY_ID == VendorTab
    );

    if (tempSelectedData) {
      setSelectedData(tempSelectedData?.FIELDS);
      let tempReduxCustomerData = props.CUSTOMER_DATA;
      tempSelectedData.FIELDS.map((val) => {
        const foundCurrentIndex = tempReduxCustomerData.find(
          (item) => item.FIELD_ID == val.FIELD_ID
        );
        if (foundCurrentIndex) {
          val.VALUE = foundCurrentIndex?.VALUE;
          val.FILE = foundCurrentIndex?.FILE;
        }
      });
      if (tempSelectedDataIndex == allCategory.length - 1) {
        setShowSubmitButton(true);
      } else {
        setShowSubmitButton(false);
      }
    }
  }, [VendorTab, props.CUSTOMER_DATA]);

  const updateCustomer = () => {
    const noErrorFound = saveDataInRedux();

    // if(!noErrorFound){

    let customerDataRedux = props.CUSTOMER_DATA;
    let customerFormData = new FormData();

    customerDataRedux.map((val) => {
      if (val.INPUT_TYPE == "file" && val.FIELD_TYPE == "Input") {
        customerFormData.append([val.FIELD_ID], val.FILE);
      } else if (val.FIELD_TYPE == "Dropdown") {
        customerFormData.append([val.FIELD_ID], val.VALUE?.value);
      } else {
        customerFormData.append([val.FIELD_ID], val.VALUE);
      }
    });

    customerFormData.set("APPLICATION_SUBMITTED", JSON.stringify(true));
    customerFormData.set(
      "APPROVERS",
      JSON.stringify(currentApplicationData.APPROVERS)
    );

    for (var pair of customerFormData.entries()) {
      console.log("sdasdasdasdasdsadkjsabdbjd", pair[0] + ", " + pair[1]);
    }
    // alert("No data")
    axios
      .post(
        AXIOS.axiosUrl + AXIOS.sap_approved_customer_update,
        customerFormData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.APPROVER == false) {
          toast.error("No approver assigned");
        } else if (response.data.CUSTOMER_CREATED == true) {
          toast.success("Customer Created Successfully");
              getCurrentApplicationDetail([]);
        }
        // alert("Done");
      })
      .catch((err) => {
        console.log("err", err);
      });

    //     }else{
    // alert("err")
    //     }
  };

  const handleInputChange = (CategoryId, FieldId, Index, value) => {
    const TypedValue = value;

    let tempSelectedData = [...selectedData];
    if (
      tempSelectedData[Index]?.MAX_LENGTH != undefined &&
      tempSelectedData[Index]?.FIELD_TYPE != "Dropdown" &&
      tempSelectedData[Index]?.INPUT_TYPE != "file"
    ) {
      if (TypedValue.length <= tempSelectedData[Index].MAX_LENGTH) {
        tempSelectedData[Index].VALUE = TypedValue;
        tempSelectedData[Index].ERROR_FLAG = false;
      } else {
        toast.error("Maximum word limit exceeded", {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
          iconTheme: {
            primary: "#713200",
            secondary: "#FFFAEE",
          },
        });
      }
    } else {
      tempSelectedData[Index].VALUE = TypedValue;
      tempSelectedData[Index].ERROR_FLAG = false;
    }

    setSelectedData(tempSelectedData);
  };

  const saveDataInRedux = () => {
    let noErrorFound = true;
    let tempSelectedData = [...selectedData];

    tempSelectedData.map((val) => {
      if (
        val.FIELD_TYPE == "Input" &&
        val.INPUT_TYPE != "file" &&
        val.IS_REQUIRED == true
      ) {
        if (val.VALUE == "") {
          val.ERROR_FLAG = true;
          noErrorFound = false;
        }
      } else if (
        val.FIELD_TYPE == "Input" &&
        val.INPUT_TYPE == "file" &&
        val.IS_REQUIRED == true
      ) {
        if (val.FILE_NAME == "") {
          val.ERROR_FLAG = true;
          noErrorFound = false;
        }
      } else if (val.FIELD_TYPE == "Dropdown" && val.IS_REQUIRED == true) {
        if (val.VALUE == "") {
          val.ERROR_FLAG = true;
          noErrorFound = false;
        }
      }
    });

    setSelectedData(tempSelectedData);
    if (noErrorFound) {
      let tempReduxCustomerData = [...props.CUSTOMER_DATA];

      selectedData.map((val) => {
        const foundIndexInRedux = tempReduxCustomerData.findIndex(
          (item) => item.FIELD_ID == val.FIELD_ID
        );
        if (foundIndexInRedux == -1) {
          tempReduxCustomerData.push({ ...val });
        } else {
          tempReduxCustomerData[foundIndexInRedux] = { ...val };
        }
      });
      props.setCustomerDataAction(tempReduxCustomerData);
      toast.success("Application Saved Successfully");
    }

    return noErrorFound;
  };

  const handleFileUpload = (CategoryId, FieldId, Index, value) => {
    let tempSelectedData = [...selectedData];
    tempSelectedData[Index].FILE = value.target.files[0];
    tempSelectedData[Index].FILE_NAME = value.target.files[0].name;

    setSelectedData(tempSelectedData);
  };
  function showOnlyLastTwo(str) {
    if (str.length <= 2) {
      return str;
    } else {
      let stars = "";
      for (let i = 0; i < str.length - 4; i++) {
        stars = stars + "*";
      }
      return str.substring(0, 4) + stars;
    }
  }

  const handleOTPVerify = () => {
    axios
      .post(AXIOS.axiosUrl + AXIOS.receiver_otp_verification, {
        APPLICATION_ID: userInfo.APPLICATION_ID,
        RECEIVER_OTP: userInfo.OTP,
      })
      .then((response) => {
        handleOTPModalClose();
      })
      .catch((err) => {
        console.log("Adasd", err);
      });
  };

  const handleOTPSend = () => {
    setCount(60);
    axios
      .post(AXIOS.axiosUrl + AXIOS.otp_send_to_receiver, {
        APPLICATION_ID: userInfo.APPLICATION_ID,
        RECEIVER_EMAIL_ID: userInfo.EMAIL_ID,
      })
      .then((response) => {
        console.log("ASdasdsa", response.data);
      })
      .catch((err) => {
        // setCount(60);
        console.log("Adasd", err);
      });
  };
  return (
    <>
      {/* <CssBaseline />
      <Header
        drawerWidth={drawerWidth}
        toggleDrawer={setShowDrawer}
        showDrawer={showDrawer}
      /> */}
      <Toaster />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 15,
          backgroundColor: "#fff",
          padding: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            // marginTop: 5,
          }}
        >
          <div className="gray-arrow-container">Customer</div>
          <div className="blue-arrow-container">Customer Create</div>
        </div>
      </div>

      <Card
        sx={{
          mt: 2,
          ml: 2,
          mr: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          className="header-image"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginLeft: 5,
            }}
          >
            {showDrawerAppBar && (
              <img
                src={IMAGES.backIcon}
                style={{
                  width: 35,
                  height: 20,
                  marginRight: 15,
                  marginBottom: 7,
                  cursor: "pointer",
                }}
                onClick={() => navigate(-1)}
                className="icon-image"
              />
            )}

            <h1
              style={{
                color: "black",
              }}
            >
              Customer Creation
            </h1>
          </Box>
        </Box>
        <Box
          className="vendor-tab"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <ImageIcon
            Icon={IMAGES.DummyIcon1}
            Style={{
              height: 40,
              width: 40,
            }}
          /> */}
          <Tabs
            value={VendorTab}
            onChange={handleSelectedLoginTab}
            textColor="#000"
            indicatorColor="#000"
            aria-label="primary"
            sx={{
              color: "white",
            }}
          >
            {allCategory.map((val) => {
              return (
                <Tab
                  value={val.CATEGORY_ID}
                  label={val.CATEGORY}
                  className="vendor-tab-item"
                  sx={{
                    fontSize: { xs: 14, md: 14 },
                    fontWeight: 600,
                    textTransform: "none",
                    marginLeft: 2,
                    border: 1,
                    borderColor: "#AFAFAF",
                    backgroundColor:
                      VendorTab == val.CATEGORY_ID ? "#22AAD2" : null,
                    color: VendorTab == val.CATEGORY_ID ? "#fff" : "#7C7C7C",
                    // borderRadius: VendorTab == val.CATEGORY_ID ? 2 : null,
                    borderRadius: "8px",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
        <Box
          // container
          p={1}
          // spacing={2}
          // my={4}
          sx={
            {
              // boxShadow: "0 0 4px rgba(0,0,0,0.1)",
              // borderRadius: 3,
              // marginLeft: 0,
              // width: "100%",
            }
          }
          className="card-background"
        >
          <Box className="customer-grid-container">
            {selectedData.map((val, index) => {
              let tempDropdown = [];

              if (val.FIELD_TYPE === "Dropdown") {
                if (val.DROPDOWN_OPTIONS != "") {
                  tempDropdown.push({ label: val, value: val });
                }
              }
              return (
                <Box className="customer-grid-item">
                  <CustomLabel
                    LabelText={`${val.LABEL}${val.IS_REQUIRED ? "*" : ""}`}
                  />

                  {val.FIELD_TYPE === "Dropdown" && (
                    <>
                      <CustomDropdown
                        Options={
                          val.DROPDOWN_OPTIONS == "" ? [] : val.DROPDOWN_OPTIONS
                        }
                        Value={val.VALUE}
                        Label={val.LABEL}
                        IS_DISABLED={val.IS_DISABLED}
                        OnChange={(e) => {
                          handleInputChange(
                            val.CATEGORY_ID,
                            val.FIELD_ID,
                            index,
                            e
                          );
                        }}
                        error={val.ERROR_FLAG}
                      />
                      {val.ERROR_FLAG && (
                        <Typography
                          sx={{
                            fontSize: 12,
                            // ml: 2,
                            padding: 0.6,
                            // m:1,
                            pl: 1,
                            color: COLORS.red,
                            backgroundColor: "white",
                          }}
                        >
                          Please select {val.LABEL}
                        </Typography>
                      )}
                    </>
                  )}

                  {val.FIELD_TYPE === "Input" && val.INPUT_TYPE === "file" && (
                    <>
                      <form
                        style={{
                          display: "flex",
                          // justifyContent:"space-between",
                          alignItems: "center",
                        }}
                      >
                        <label
                          htmlFor="file-upload"
                          className={`custom-file-upload${
                            val.ERROR_FLAG ? " text-error" : ""
                          }`}
                          style={{
                            fontSize: 14,
                          }}
                        >
                          <i className="fa fa-cloud-upload" /> Upload Image
                        </label>
                        <input
                          id="file-upload"
                          name="upload_cont_img"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleFileUpload(
                              val.CATEGORY_ID,
                              val.FIELD_ID,
                              index,
                              e
                            );
                          }}
                        />
                        <label
                          style={{
                            fontSize: 14,
                            marginLeft: 5,
                          }}
                        >
                          <i className="fa fa-cloud-upload" /> {val.FILE_NAME}
                        </label>
                      </form>
                      {val.ERROR_FLAG && (
                        <p className="helper-text">Please upload file</p>
                      )}
                    </>
                  )}
                  {val.FIELD_TYPE === "Input" && val.INPUT_TYPE != "file" && (
                    <>
                      <Box style={{}}>
                        <CustomerInputBox
                          Disabled={val.IS_DISABLED}
                          id={val.FIELD_ID}
                          Placeholder={val.LABEL}
                          Type={val.INPUT_TYPE}
                          onChange={(e) => {
                            if (val.INPUT_TYPE == "file") {
                              handleFileUpload(
                                val.CATEGORY_ID,
                                val.FIELD_ID,
                                index,
                                e
                              );
                            } else {
                              handleInputChange(
                                val.CATEGORY_ID,
                                val.FIELD_ID,
                                index,
                                e.target.value
                              );
                            }
                          }}
                          isRequired={val.IS_REQUIRED}
                          error={val.ERROR_FLAG}
                          Value={val.INPUT_TYPE == "file" ? "" : val.VALUE}
                          helperText={
                            val.ERROR_FLAG ? "Please enter " + val.LABEL : ""
                          }
                        />
                        <p>{val.FILE_NAME}</p>
                      </Box>
                    </>
                  )}
                </Box>
              );
            })}
          </Box>
          {ShowActionButton && (
            <Box
              sx={{
                justifyContent: "flex-end",
                display: "flex",
                m: 1,
              }}
            >
              <button
                className="signup-button"
                style={{
                  // marginLeft: 15,
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 4,
                }}
                onClick={() => {
                  saveDataInRedux();
                }}
              >
                Save
              </button>
              {showSubmitButton && (
                <button
                  className="signup-button"
                  style={{
                    marginLeft: 15,
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderRadius: 4,
                  }}
                  onClick={() => {
                    updateCustomer();
                  }}
                >
                  Submit
                </button>
              )}
            </Box>
          )}
        </Box>
      </Card>

      <Modal
        open={OTPModalFlag}
        onClose={handleOTPModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="otp-modal-container">
          <div className="login-container">
            <div className="login-left-container">
              <div className="login-left-inner-container">
                <h2 className="header-text inter-font"> Hello! Welcome Back</h2>
                {/* <p className="sub-heading-text inter-font">
                  Enter your details here to sign in
                </p> */}
                <div className="form-container">
                  <p className="input-box-title">Email Address</p>
                  <CustomInput
                    Placeholder={"Enter your email address"}
                    Value={
                      userInfo.EMAIL_ID != undefined
                        ? showOnlyLastTwo(userInfo.EMAIL_ID)
                        : ""
                    }
                    Disabled={true}
                    onChange={(e) => {
                      // setUserCredentials((prev) => ({
                      //   ...prev,
                      //   USERNAME: e.target.value,
                      // }));
                    }}
                  />
                  <p className="input-box-title">OTP</p>
                  <CustomInput
                    Placeholder={"OTP"}
                    Value={userInfo.OTP}
                    onChange={(e) => {
                      setUserInfo((prev) => ({
                        ...prev,
                        OTP: e.target.value,
                      }));
                    }}
                  />
                  <div className="forgot-password-container">
                    {count != 0 ? (
                      <p className="forgot-password-text">
                        {" "}
                        Try again in {count}s
                      </p>
                    ) : (
                      <p
                        className="forgot-password-text"
                        onClick={() => {
                          handleOTPSend();
                        }}
                      >
                        Send OTP?
                      </p>
                    )}
                  </div>
                  <button
                    className="signup-button"
                    onClick={() => {
                      // navigate(CUSTOMER_CREATION);
                      // handleLogin();
                      handleOTPVerify();
                    }}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="login-right-container cover-img"></div>
          </div>
          {/* <div class="cookieCard">
            <p class="cookieHeading">Verify User</p>
            <p class="cookieHeading">{userInfo.EMAIL_ID!=undefined?showOnlyLastTwo(userInfo.EMAIL_ID):""}</p>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
              }}
            >
              <div>
                <CustomerInputBox Placeholder={"OTP"} />
              </div>
              <div>
                <button
                  className="acceptButton"
                  style={{
                    minHeight: 47,
                    maxHeight: 47,
                    display:"flex",
                    alignItems:"center"
                  }}
                >
                  Send OTP
                  <svg
                    fill="#fff"
                    width={30}
                    height={30}
                    viewBox="0 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>send</title>
                    <path d="M0 16l12 4 4 12 16-32zM14.016 18.016l12-12-10.016 17.984z"></path>
                  </svg>
                </button>
              </div>{" "}
            </div>
          </div> */}
        </Box>
      </Modal>
    </>
  );
}

// export default CustomerCreation;

const mapStateToProps = (state) => ({
  CUSTOMER_DATA: state.customer.customer_data,
});

export default connect(mapStateToProps, { setCustomerDataAction })(
  CustomerEmailCreation
);
