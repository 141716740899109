import {
  Card,
  Chip,
  CssBaseline,
  Divider,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../components/AppDrawer/Header";
import { Toaster } from "react-hot-toast";
import { COLORS } from "../Utils/Theme";
import axios from "axios";
import AXIOS from "../Utils/AXIOS";
import { Box } from "@mui/system";
import { SlOptionsVertical } from "react-icons/sl";

import "./Style.css";
import ReactDataTable from "../../components/ReactDataTable/ReactDataTable";
import DataTable from "react-data-table-component";
import CustomInput from "../../components/CustomInput/CustomInput";
import CloseIcon from "@mui/icons-material/Close";
import MainScreen from "../../components/AppDrawer/MainScreen";
import { CUSTOMER } from "../Utils/Routes";
const LOGGED_IN_ID = "E0001";

const detailsModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "1px solid white",
  // boxShadow: 24,
  backgroundColor: "white",
  p: 2,
};
const drawerWidth = 282;

function CustomerSap() {
  const [Tbody, setTbody] = useState([]);
  const [customerFieldsData, setCustomerFieldsData] = useState([]);
  const [noDataFound, setNoDataFound] = useState(true);
  const [ApplicationDetailsModal, setApplicationDetailsModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [allCategory, setAllCategory] = useState([]);

  const TableHeader = [
    "Customer ID",
    "Customer Name",
    "Email",
    // "Approval Name",
    "Status",
  ];

  useEffect(() => {
    getCustomerCreationFields();
  }, []);
  useEffect(() => {
    getAllCustomerApplicationData("2");
  }, [customerFieldsData]);
  const [VendorTab, setVendorTab] = useState(1);
  const handleSelectedLoginTab = (event, newValue) => {
    console.log("ndskjassd", newValue);

    setVendorTab(newValue);
  };

  useEffect(() => {
    const tempSelectedData = allCategory.find(
      (val) => val.CATEGORY_ID == VendorTab
    );

    if (tempSelectedData) {
      console.log("ASdasdasdsad", tempSelectedData);
      setSelectedData(tempSelectedData?.FIELDS);
      // let tempReduxCustomerData = props.CUSTOMER_DATA;
      // tempSelectedData.FIELDS.map((val) => {
      //   const foundCurrentIndex = tempReduxCustomerData.find(
      //     (item) => item.FIELD_ID == val.FIELD_ID
      //   );
      //   console.log("asdads", foundCurrentIndex);
      //   if (foundCurrentIndex) {
      //     val.VALUE = foundCurrentIndex?.VALUE;
      //     val.FILE = foundCurrentIndex?.FILE;
      //   }
      // });
      // if (tempSelectedDataIndex == allCategory.length - 1) {
      //   setShowSubmitButton(true);
      // } else {
      //   setShowSubmitButton(false);
      // }
    }
  }, [VendorTab]);

  const addFieldsInApplicationData = (ApplicationData) => {
    let tempCustomerFieldsData = [...customerFieldsData];
    // const findApplicationData = Tbody.find(
    //   (item) => item.APPLICATION_ID == ApplicationData.APPLICATION_ID
    // );

    // const tempCurrentApprovalData = findApplicationData.APPROVERS.find(
    //   (item) => item.EMP_ID == LOGGED_IN_ID
    // );
    // console.log("tempCurrentApprovalData", tempCurrentApprovalData);
    // setCurrentApprovalDetails(tempCurrentApprovalData);

    let responseData = [];

    Object.keys(ApplicationData).forEach(function (key, index, value) {
      responseData.push({ FIELD_ID: key, VALUE: ApplicationData[key] });
    });

    console.log("Asdasdadadasd", ApplicationData);

    tempCustomerFieldsData.map((val) => {
      const foundData = responseData.find((item) => {
        return item.FIELD_ID == val.FIELD_ID;
      });
      if (foundData) {
        val.VALUE = foundData.VALUE;
      }
    });

    console.log("asdasdasdasd", customerFieldsData);

    let tempCategoryArr = [];
    tempCustomerFieldsData.map((val) => {
      let categoryIndex = tempCategoryArr.findIndex(
        (item) => val.CATEGORY_ID == item.CATEGORY_ID
      );
      console.log("adasdsadadasdasd", val);

      if (categoryIndex == -1) {
        tempCategoryArr.push({
          CATEGORY_ID: val.CATEGORY_ID,
          CATEGORY: val.CATEGORY,
          APPLICATION_ID: ApplicationData.APPLICATION_ID,
          FIELDS: [
            {
              ...val,
            },
          ],
        });
      } else {
        tempCategoryArr[categoryIndex].FIELDS.push({
          ...val,
        });
      }
    });
    setVendorTab(tempCategoryArr[0].CATEGORY_ID);

    return tempCategoryArr;
  };

  const getCustomerCreationFields = async () => {
    await axios
      .get(AXIOS.axiosUrl + AXIOS.customer_field_get)
      .then((response) => {
        console.log("Asdadadsas", response.data);
        setCustomerFieldsData(response.data);

        // console.log("tempCategoryArr,", tempCategoryArr);
        // setAllCategory(response.data);
        // setVendorTab(tempCategoryArr[0].CATEGORY_ID);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCustomerApplicationData = async (Flag) => {
    await axios
      .get(AXIOS.axiosUrl + AXIOS.sap_approved_customer_get)
      .then((response) => {
        console.log("asdasdasdsadasdasd", response.data);
        if (response.data.length > 0) {
          setNoDataFound(false);
        } else {
          setNoDataFound(true);
        }

        // let tempResponseData = [];
        // if (customerFieldsData.length > 0) {
        //   response.data.map((val) => {
        //     const tempData = addFieldsInApplicationData(val);
        // console.log("asdasdasraaahlll", tempData);

        //     tempResponseData.push({ ...tempData[0] });
        //   });
        // }
        setTbody(response.data);
      })
      .catch((err) => {
        console.log("aaas", err);
      });
  };

  const [allRowSelected, setAllRowSelected] = useState(false);

  const handleToggleAllRow = () => {
    let tempAllRowSelected = allRowSelected;

    setAllRowSelected(!tempAllRowSelected);

    let tempTBody = [...Tbody];
    tempTBody.map((val) => {
      val.IS_CHECKED = !tempAllRowSelected;
    });

    setTbody(tempTBody);
  };
  const handleClickedRowCheckBox = (clickedData, index) => {
    let tempTbody = [...Tbody];
    tempTbody[index].IS_CHECKED = !tempTbody[index].IS_CHECKED;
    setTbody(tempTbody);
  };

  const columns = [
    {
      name: (
        <input
          Type={"checkbox"}
          checked={allRowSelected}
          onClick={() => {
            handleToggleAllRow();
          }}
        />
      ),
      selector: (val) => val.sap_vendor_token,
      sortable: false,
      width: "80px",
      cell: (val, index) => (
        <input
          type="checkbox"
          checked={val?.IS_CHECKED}
          onClick={() => {
            handleClickedRowCheckBox(val, index);
          }}
        />
      ),
    },
    {
      name: "Customer ID",
      selector: (val) => val.sap_vendor_token,
      sortable: true,
      //  cell: {textAlign: 'right'},

      cell: (val) => (
        <span
          className="text-center"
          style={{
            textAlign: "center",
          }}
        >
          {val.sap_vendor_token}
        </span>
      ),
    },
    {
      name: "Customer Name",
      selector: (val) => val.NAME1,
      sortable: true,
    },
    {
      name: "Details",
      // selector: (val) => val.,
      sortable: false,
      cell: (val) => {
        return (
          <p
            // style={{
            //   borderWidth: 1,
            //   borderColor: "#22AAD2",
            //   color: "#22AAD2",
            // }}
            className="view-details-button"
            onClick={() => {
              setApplicationDetailsModal(true);

              setAllCategory(addFieldsInApplicationData(val));
              // );
              // setSelectedData(val);
            }}
          >
            View Details
          </p>
        );
      },
    },
    // {
    //   name: "Details",
    //   // selector: (val) => val.,
    //   sortable: false,
    //   cell: (val) => {
    //     return (
    //       <button
    //         class="Documents-btn"
    //         onClick={() => {
    //           setApplicationDetailsModal(true);

    //           setSelectedData(addFieldsInApplicationData(val));
    //           // );
    //           // setSelectedData(val);
    //         }}
    //       >
    //         <span class="folderContainer">
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             fill="none"
    //             viewBox="0 0 146 113"
    //             height="113"
    //             width="146"
    //             class="fileBack"
    //           >
    //             <path
    //               fill="url(#paint0_linear_117_4)"
    //               d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z"
    //             ></path>
    //             <defs>
    //               <linearGradient
    //                 gradientUnits="userSpaceOnUse"
    //                 y2="95.4804"
    //                 x2="72.93"
    //                 y1="0"
    //                 x1="0"
    //                 id="paint0_linear_117_4"
    //               >
    //                 <stop stop-color="#8F88C2"></stop>
    //                 <stop stop-color="#5C52A2" offset="1"></stop>
    //               </linearGradient>
    //             </defs>
    //           </svg>
    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             fill="none"
    //             viewBox="0 0 88 99"
    //             height="99"
    //             width="88"
    //             class="filePage"
    //           >
    //             <rect
    //               fill="url(#paint0_linear_117_6)"
    //               height="99"
    //               width="88"
    //             ></rect>
    //             <defs>
    //               <linearGradient
    //                 gradientUnits="userSpaceOnUse"
    //                 y2="160.5"
    //                 x2="81"
    //                 y1="0"
    //                 x1="0"
    //                 id="paint0_linear_117_6"
    //               >
    //                 <stop stop-color="white"></stop>
    //                 <stop stop-color="#686868" offset="1"></stop>
    //               </linearGradient>
    //             </defs>
    //           </svg>

    //           <svg
    //             xmlns="http://www.w3.org/2000/svg"
    //             fill="none"
    //             viewBox="0 0 160 79"
    //             height="79"
    //             width="160"
    //             class="fileFront"
    //           >
    //             <path
    //               fill="url(#paint0_linear_117_5)"
    //               d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z"
    //             ></path>
    //             <defs>
    //               <linearGradient
    //                 gradientUnits="userSpaceOnUse"
    //                 y2="82.8317"
    //                 x2="66.9106"
    //                 y1="8.71323"
    //                 x1="38.7619"
    //                 id="paint0_linear_117_5"
    //               >
    //                 <stop stop-color="#C3BBFF"></stop>
    //                 <stop stop-color="#51469A" offset="1"></stop>
    //               </linearGradient>
    //             </defs>
    //           </svg>
    //         </span>
    //       </button>
    //     );
    //   },
    // },
    {
      name: "Status",
      selector: (val) => val.APPLICATION_ID,
      sortable: true,
      cell: (val) => {
        return (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Chip
              size="small"
              label="Active"
              sx={{
                backgroundColor: "#6EBB67",
                color: COLORS.white,
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    //  <MainScreen drawerWidth={drawerWidth}>

    //  </MainScreen>
    <MainScreen drawerWidth={drawerWidth} ActiveKey={CUSTOMER}>
      <Toaster />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 15,
          backgroundColor: "#fff",
          padding: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            // marginTop: 5,
          }}
        >
          <div className="gray-arrow-container">Customer</div>
          <div className="blue-arrow-container">Customer</div>
        </div>
      </div>
      <Card
        sx={{
          // padding:1
          mt: 2,
          ml: 2,
          mr: 2,
        }}
      >
        <h2
          style={{
            padding: 10,
            // backgroundColor: "#49536b",
            color: "black",
          }}
          className="screen-name-header"
        >
          Customer
        </h2>
        <div
          style={{
            position: "absolute",
            marginLeft: 5,
            marginBottom: -10,
          }}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <button
              className="approve-reject-button"
              style={{
                // marginLeft: 15,
                // textAlign: "center",
                justifyContent: "center",
                display: "flex",
                // borderRadius: 4,
                backgroundColor: "#69B375",
              }}
              onClick={() => {
                // createNewCustomer();
              }}
            >
              Active
            </button>
            <button
              className="approve-reject-button"
              style={{
                marginLeft: 5,
                textAlign: "center",
                justifyContent: "center",
                // display: "flex",
                // borderRadius: 4,
                backgroundColor: "#A2A2A2",
              }}
              onClick={() => {
                // createNewCustomer();
              }}
            >
              Inactive
            </button>
          </div>
        </div>
        <Card>
          <ReactDataTable columns={columns} data={Tbody} loading={true} />
        </Card>
      </Card>
      <Modal
        open={ApplicationDetailsModal}
        onClose={() => {
          setApplicationDetailsModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={detailsModalStyle} className="details-modal">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>Customer Details</h2>
            <Box
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                setApplicationDetailsModal(false);
              }}
            >
              <CloseIcon />
            </Box>
          </Box>
          <Box
            sx={{
              // height:2,
              backgroundColor: "gray",
              mb: 1,
            }}
          >
            <Divider />
          </Box>
          <Tabs
            value={VendorTab}
            onChange={handleSelectedLoginTab}
            textColor="#000"
            indicatorColor="#000"
            aria-label="primary"
            sx={{
              color: "white",
            }}
          >
            {allCategory.map((val) => {
              return (
                <Tab
                  value={val.CATEGORY_ID}
                  label={val.CATEGORY}
                  className="vendor-tab-item"
                  sx={{
                    fontSize: { xs: 14, md: 14 },
                    fontWeight: 600,
                    textTransform: "none",
                    marginLeft: 2,
                    border: 1,
                    borderColor: "#AFAFAF",
                    backgroundColor:
                      VendorTab == val.CATEGORY_ID ? "#22AAD2" : null,
                    color: VendorTab == val.CATEGORY_ID ? "#fff" : "#7C7C7C",
                    // borderRadius: VendorTab == val.CATEGORY_ID ? 2 : null,
                    borderRadius: "8px",
                  }}
                />
              );
            })}
          </Tabs>
          <div className="customer-no-edit-approval-grid-container">
            {selectedData.map((item) => {
              // console.log("sadasdsadasdas",val);
              return (
                <>
                  {/* <Typography
                  sx={{
                    color: COLORS.white,
                    fontWeight: 700,
                    fontSize: 20,
                    p: 1,
                    backgroundColor: "#637091",
                  }}
                >
                  {val.CATEGORY}
                </Typography> */}

                  {/* {val.FIELDS.map((item) => {
                    return ( */}
                  <div>
                    {item.FIELD_TYPE === "Input" &&
                    item.INPUT_TYPE === "file" ? (
                      <label
                        style={{
                          fontSize: 14,
                          marginLeft: 5,
                          backgroundColor: "#ADD8E6",
                          margin: 7,
                        }}
                        className="custom-file-upload"
                        onClick={() => {
                          window.open(item.VALUE, "_blank", "noreferrer");
                        }}
                      >
                        <i className="fa fa-cloud-upload" /> View File
                      </label>
                    ) : (
                      <Typography
                        sx={{
                          // color: COLORS.white,
                          // fontWeight: 700,
                          fontSize: 20,
                          p: 1,
                          // backgroundColor: COLORS.primary,
                        }}
                      >
                        {item.VALUE}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        // color: COLORS.white,
                        fontWeight: 700,
                        fontSize: 20,
                        p: 1,
                        // backgroundColor: COLORS.primary,
                      }}
                    >
                      {item.LABEL}
                    </Typography>
                    <Divider />
                  </div>
                  {/* );
                  })} */}
                </>
              );
            })}
          </div>
        </Box>
      </Modal>
    </MainScreen>
  );
}

export default CustomerSap;
