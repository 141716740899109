import {
  Button,
  CssBaseline,
  Tab,
  Card,
  Tabs,
  Tooltip,
  Typography,
  Modal,
  Divider,
} from "@mui/material";
import { Box, borderRadius, padding } from "@mui/system";
import React, { useEffect } from "react";
import Header from "../../components/AppDrawer/Header";
import ImageIcon from "../../components/ImageIcon/ImageIcon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MainScreen from "../../components/AppDrawer/MainScreen";
import IMAGES from "../../assets/IMAGES";
import "./style.css";
import axios from "axios";
import AXIOS from "../Utils/AXIOS";
import CustomLabel from "../../components/CustomLabel/CustomLabel";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomInput from "../../components/CustomInput/CustomInput";
import { COLORS } from "../Utils/Theme";
import { connect } from "react-redux";
import { setCustomerDataAction } from "../../redux/action/customerDataAction";
import toast, { Toaster } from "react-hot-toast";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomerInputBox from "../../components/CustomerInputBox/CustomerInputBox";
import { CUSTOMER_CREATION } from "../Utils/Routes";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as XLSX from "xlsx";
import Icons from "../../assets/Icons";
const drawerWidth = 282;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "600px",
  // height: "400px",
  // bgcolor: "#fff",
  // padding: "10px",
  borderRadius: "5px",
  // border: '0px solid #fff',
  boxShadow: 24,
};

function CustomerCreation(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
  const navigate = useNavigate();
  const [showDrawer, setShowDrawer] = useState(false);
  const [VendorTab, setVendorTab] = useState(1);
  const [showDrawerAppBar, setShowDrawerAppBar] = useState(null);
  const [ExcelImportModalFlag, setExcelImportModalFlag] = useState(false);
  const handleExcelImportModalClose = () => setExcelImportModalFlag(false);
  const [allCategory, setAllCategory] = useState([]);
  const [FieldsData, setFieldsData] = useState([]);

  const handleSelectedLoginTab = (event, newValue) => {

    setVendorTab(newValue);
  };

  useEffect(() => {
    getCustomerCreationFields();
  }, []);

  const getCustomerCreationFields = () => {
    axios
      .get(AXIOS.axiosUrl + AXIOS.customer_field_get)
      .then((response) => {
        let tempCategoryArr = [];

        setFieldsData(response.data)
        response.data.map((val) => {
          let categoryIndex = tempCategoryArr.findIndex(
            (item) => val.CATEGORY_ID == item.CATEGORY_ID
          );
          let dropDownData = [];
          if (val.DROPDOWN_OPTIONS == "") {
            dropDownData = val.DROPDOWN_OPTIONS;
          } else {
            val.DROPDOWN_OPTIONS.map((val) => {
              dropDownData.push({ ...val });

            });
          }
          if (categoryIndex == -1) {
            tempCategoryArr.push({
              CATEGORY_ID: val.CATEGORY_ID,
              CATEGORY: val.CATEGORY,
              FIELDS: [
                {
                  ...val,
                  VALUE: "",
                  FILE: [],
                  DROPDOWN_OPTIONS: dropDownData,
                },
              ],
            });
          } else {
            tempCategoryArr[categoryIndex].FIELDS.push({
              ...val,
              VALUE: "",
              FILE: [],
              DROPDOWN_OPTIONS: dropDownData,
            });
          }
        });
        setAllCategory(tempCategoryArr);
        setVendorTab(tempCategoryArr[0].CATEGORY_ID);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [selectedData, setSelectedData] = useState([]);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  useEffect(() => {
    const tempSelectedData = allCategory.find(
      (val) => val.CATEGORY_ID == VendorTab
    );
    const tempSelectedDataIndex = allCategory.findIndex(
      (val) => val.CATEGORY_ID == VendorTab
    );

    if (tempSelectedData) {
      setSelectedData(tempSelectedData?.FIELDS);
      let tempReduxCustomerData = props.CUSTOMER_DATA;
      tempSelectedData.FIELDS.map((val) => {
        const foundCurrentIndex = tempReduxCustomerData.find(
          (item) => item.FIELD_ID == val.FIELD_ID
        );
        if (foundCurrentIndex) {
          val.VALUE = foundCurrentIndex?.VALUE;
          val.FILE = foundCurrentIndex?.FILE;
        }
      });
      if (tempSelectedDataIndex == allCategory.length - 1) {
        setShowSubmitButton(true);
      } else {
        setShowSubmitButton(false);
      }
    }
  }, [VendorTab]);

  const createNewCustomer = () => {
    const noErrorFound = saveDataInRedux();

    // if(!noErrorFound){

    let customerDataRedux = props.CUSTOMER_DATA;
    let customerFormData = new FormData();

    customerDataRedux.map((val) => {
      if (val.INPUT_TYPE == "file" && val.FIELD_TYPE == "Input") {
        customerFormData.append([val.FIELD_ID], val.FILE);
      } else if (val.FIELD_TYPE == "Dropdown") {
        customerFormData.append([val.FIELD_ID], val.VALUE?.value);
      } else {
        customerFormData.append([val.FIELD_ID], val.VALUE);
      }
    });
    // alert("No data")
    axios
      .post(AXIOS.axiosUrl + AXIOS.customer_create, customerFormData)
      .then((response) => {

        if (response.data.APPROVER == false) {
          toast.error("No approver assigned");
        } else if (response.data.CUSTOMER_CREATED == true) {
          toast.success("Customer Created Successfully");
        }
        // alert("Done");
      })
      .catch((err) => {
        console.log("err", err);
      });

    //     }else{
    // alert("err")
    //     }
  };
  const ExcelUploadCreateCustomer = () => {

    let noErrorFound = true;
    let tempFieldsData = [...FieldsData];

    tempFieldsData.map((val) => {
      if (val.IS_REQUIRED == true && val.VALUE == "") {

          val.ERROR_FLAG = true;
          noErrorFound = false;
      } 
      
      // else if (
      //   val.FIELD_TYPE == "Input" &&
      //   val.INPUT_TYPE == "file" &&
      //   val.IS_REQUIRED == true
      // ) {
      //   if (val.FILE_NAME == "") {
      //     val.ERROR_FLAG = true;
      //     noErrorFound = false;
      //   }
      // } else if (val.FIELD_TYPE == "Dropdown" && val.IS_REQUIRED == true) {
      //   if (val.VALUE == "") {
      //     val.ERROR_FLAG = true;
      //     noErrorFound = false;
      //   }
      // }
    });
    // const noErrorFound = saveDataInRedux();
    setFieldsData(tempFieldsData);

    if(noErrorFound==true){

    let customerDataRedux = uploadedExcelJson;
    let customerFormData = new FormData();

    customerDataRedux.map((val) => {
      if (val.INPUT_TYPE == "file" && val.FIELD_TYPE == "Input") {
        customerFormData.append([val.FIELD_ID], val.FILE);
      } else if (val.FIELD_TYPE == "Dropdown") {
        customerFormData.append([val.FIELD_ID], val.VALUE?.value);
      } else {
        customerFormData.append([val.FIELD_ID], val.VALUE);
      }
    });
    // alert("No data")
    axios
      .post(AXIOS.axiosUrl + AXIOS.customer_create, customerFormData)
      .then((response) => {

        if (response.data.APPROVER == false) {
          toast.error("No approver assigned");
        } else if (response.data.CUSTOMER_CREATED == true) {
          toast.success("Customer Created Successfully");
        }
        // alert("Done");
      })
      .catch((err) => {
        console.log("err", err);
      });

    //     }else{
    // alert("err")
        }else{
                toast.error("Please provide Mandatory fields data", {
                  style: {
                    // border: "1px solid red",
                    padding: "16px",
                    color: "#000",
                  },
                  iconTheme: {
                    primary: "red",
                    secondary: "#FFFAEE",
                  },
                });
        }
  };

  const handleInputChange = (CategoryId, FieldId, Index, value) => {
    const TypedValue = value;

    let tempSelectedData = [...selectedData];
         console.log(
           "tempSelectedData",
           tempSelectedData[Index],
           TypedValue.length,
           TypedValue
         );
    if (
      tempSelectedData[Index]?.MAX_LENGTH != undefined &&
      tempSelectedData[Index]?.FIELD_TYPE != "Dropdown" &&
      tempSelectedData[Index]?.INPUT_TYPE != "file"
    ) {
 
      console.log("ASdasdasda", TypedValue.length);
      if (TypedValue.length <= Number(tempSelectedData[Index].MAX_LENGTH)) {
        tempSelectedData[Index].VALUE = TypedValue;
        tempSelectedData[Index].ERROR_FLAG = false;
      } else {
        toast.error("Maximum word limit exceeded", {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
          iconTheme: {
            primary: "#713200",
            secondary: "#FFFAEE",
          },
        });
      }
    } else {
      tempSelectedData[Index].VALUE = TypedValue;
      tempSelectedData[Index].ERROR_FLAG = false;
    }

    setSelectedData(tempSelectedData);
  };

  const saveDataInRedux = () => {
    let noErrorFound = true;
    let tempSelectedData = [...selectedData];

    tempSelectedData.map((val) => {
      if (
        val.FIELD_TYPE == "Input" &&
        val.INPUT_TYPE != "file" &&
        val.IS_REQUIRED == true
      ) {
        if (val.VALUE == "") {
          val.ERROR_FLAG = true;
          noErrorFound = false;
        }
      } else if (
        val.FIELD_TYPE == "Input" &&
        val.INPUT_TYPE == "file" &&
        val.IS_REQUIRED == true
      ) {
        if (val.FILE_NAME == "") {
          val.ERROR_FLAG = true;
          noErrorFound = false;
        }
      } else if (val.FIELD_TYPE == "Dropdown" && val.IS_REQUIRED == true) {
        if (val.VALUE == "") {
          val.ERROR_FLAG = true;
          noErrorFound = false;
        }
      }
    });

    setSelectedData(tempSelectedData);
    if (noErrorFound) {
      let tempReduxCustomerData = [...props.CUSTOMER_DATA];

      selectedData.map((val) => {
        const foundIndexInRedux = tempReduxCustomerData.findIndex(
          (item) => item.FIELD_ID == val.FIELD_ID
        );
        if (foundIndexInRedux == -1) {
          tempReduxCustomerData.push({ ...val });
        } else {
          tempReduxCustomerData[foundIndexInRedux] = { ...val };
        }
      });
      props.setCustomerDataAction(tempReduxCustomerData);
      toast.success("Application Saved Successfully");
    }

    return noErrorFound;
  };


  const handleFileUpload = (CategoryId, FieldId, Index, value) => {
    let tempSelectedData = [...selectedData];
    tempSelectedData[Index].FILE = value.target.files[0];
    tempSelectedData[Index].FILE_NAME = value.target.files[0].name;

    setSelectedData(tempSelectedData);
  };

  function isValidEmail(email) {
    // Regular expression for email validation
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  const [CustomerEmailDetails, setCustomerEmailDetails] = useState({
    EMAIL_ID: "",
    ERROR_FLAG: false,
  });
  const sendURLCustomer = () => {
    if (isValidEmail(CustomerEmailDetails.EMAIL_ID)) {
      let customerDataRedux = props.CUSTOMER_DATA;
      let customerFormData = new FormData();
      customerDataRedux.map((val) => {
        if (val.INPUT_TYPE == "file" && val.FIELD_TYPE == "Input") {
          customerFormData.append([val.FIELD_ID], val.FILE);
        } else if (val.FIELD_TYPE == "Dropdown") {
          customerFormData.append([val.FIELD_ID], val.VALUE?.value);
        } else {
          customerFormData.append([val.FIELD_ID], val.VALUE);
        }
      });
      customerFormData.append(
        "RECEIVER_EMAIL_ID",
        CustomerEmailDetails.EMAIL_ID
      );

      axios
        .post(AXIOS.axiosUrl + AXIOS.customer_create, customerFormData)
        .then((response) => {

          if (response.data.APPROVER == false) {
            toast.error("No approver assigned");
          } else if (response.data.CUSTOMER_CREATED == true) {
            toast.success("URL sent to customer");
          }
          // alert("Done");
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else {
      setCustomerEmailDetails((prev) => ({
        ...prev,
        ERROR_FLAG: true,
      }));
    }
  };

  const FocusDataInRedux = () => {
    let tempReduxCustomerData = [...props.CUSTOMER_DATA];

    selectedData.map((val) => {
      const foundIndexInRedux = tempReduxCustomerData.findIndex(
        (item) => item.FIELD_ID == val.FIELD_ID
      );
      if (foundIndexInRedux == -1) {
        tempReduxCustomerData.push({ ...val });
      } else {
        tempReduxCustomerData[foundIndexInRedux] = { ...val };
      }
    });
    props.setCustomerDataAction(tempReduxCustomerData);
  };

  const [uploadedExcelJson, setUploadedExcelJson] = useState([]);
  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        let json = XLSX.utils.sheet_to_json(worksheet);
        json = json[0];

        let responseData = [];

        Object.keys(json).forEach(function (key, index, value) {
          responseData.push({ FIELD_ID: key, VALUE: json[key] });
        });

        let tempFieldsData=[...FieldsData]


        tempFieldsData.map((val)=>{
          val.VALUE=""
        });


                responseData.map((val) => {
                  // tempFieldsData.map((item) => {
                    const innerIndex = tempFieldsData.findIndex(
                      (innerVal) => innerVal.FIELD_ID == val.FIELD_ID
                    );
              
                  // });
                       let value;
                        if (innerIndex != -1) {
                          
                               if (tempFieldsData[innerIndex].FIELD_TYPE == "Dropdown") {
                                 let CurrentDropDownOption = tempFieldsData[
                                   innerIndex
                                 ].DROPDOWN_OPTIONS.find(
                                   (option) => option.value == val.VALUE
                                 );
                                //  console.log(
                                //    "asdkhabsdbshjdasd",
                                //    CurrentDropDownOption
                                //  );
                                 if (CurrentDropDownOption) {
                                   value = CurrentDropDownOption;
                                 }
                               } else {
                                 value = val.VALUE;
                               }
                          tempFieldsData[innerIndex].VALUE = value;
                        }
                });

        setUploadedExcelJson(tempFieldsData);

      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

 const exportFields = () => {
  let data=[]
  FieldsData.map((val)=>{
data.push({[val.FIELD_ID]:""})
  })
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "CustomerFields.xlsx");
  };
  return (
    <>
      {/* <CssBaseline />
      <Header
        drawerWidth={drawerWidth}
        toggleDrawer={setShowDrawer}
        showDrawer={showDrawer}
      /> */}
      <MainScreen drawerWidth={drawerWidth} ActiveKey={CUSTOMER_CREATION}>
        <Toaster />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 15,
            backgroundColor: "#fff",
            padding: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              // marginTop: 5,
            }}
          >
            <div className="gray-arrow-container">Customer</div>
            <div className="blue-arrow-container">Customer Create</div>
          </div>
        </div>

        <Card
          sx={{
            mt: 2,
            ml: 2,
            mr: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            // className="header-image"
          >
            <div
              sx={{
                display: "flex",
                alignItems: "center",
                // justifyContent: "space-between",
                marginLeft: 5,
                // display: "flex",
                // justifyContent: "space-between",
              }}
            >
              <h1
                style={{
                  color: "black",
                  marginLeft: 5,
                }}
              >
                Customer Creation
              </h1>
            </div>
            <div>
              {/* hello */}
              <CustomLabel LabelText={"Share Customer form"} />
              <div
                style={{
                  // width: "80%",
                  display: "flex",
                }}
              >
                <div>
                  <CustomerInputBox
                    Placeholder={"Customer's Email ID"}
                    Value={CustomerEmailDetails.EMAIL_ID}
                    onChange={(e) => {
                      setCustomerEmailDetails((prev) => ({
                        ...prev,
                        EMAIL_ID: e.target.value,
                      }));
                    }}
                    error={CustomerEmailDetails.ERROR_FLAG}
                    helperText={
                      CustomerEmailDetails.ERROR_FLAG
                        ? "Please enter a valid Email ID"
                        : ""
                    }
                  />
                </div>
                <button
                  className="signup-button"
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderRadius: 4,
                    minWidth: 40,
                    maxWidth: 40,
                    marginRight: 5,
                    marginLeft: 5,
                    minHeight: 47,
                    maxHeight: 47,
                  }}
                  onClick={() => {
                    sendURLCustomer();
                  }}
                >
                  <svg
                    fill="#fff"
                    width={30}
                    height={30}
                    viewBox="0 0 32 32"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>send</title>
                    <path d="M0 16l12 4 4 12 16-32zM14.016 18.016l12-12-10.016 17.984z"></path>
                  </svg>
                </button>
                {/* <button
                style={{
                  backgroundColor:"#fff"
                }}
                  // className="signup-button"
                  // style={{
                  //   textAlign: "center",
                  //   justifyContent: "center",
                  //   display: "flex",
                  //   borderRadius: 4,
                  //   minWidth: 40,
                  //   maxWidth: 40,
                  //   marginRight: 5,
                  //   marginLeft: 5,
                  //   minHeight: 47,
                  //   maxHeight: 47,
                  //   backgroundColor: "#fff",
                  // }}
                  onClick={() => {
                    setExcelImportModalFlag(true);
                  }}
                > */}
                <img
                  src={Icons.excel_upload}
                  style={{
                    height: 40,
                    width: 40,
                    cursor: "pointer",
                  }}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                />
                <div>
                  {/* <Button>Dashboard</Button> */}
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setExcelImportModalFlag(true);
                        handleClose();
                      }}
                    >
                      Import Excel
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        exportFields();
                      }}
                    >
                      Export Fields
                    </MenuItem>
                  </Menu>
                </div>
                {/* </button> */}
              </div>
            </div>
          </Box>

          <Box
            className="vendor-tab"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* <ImageIcon
            Icon={IMAGES.DummyIcon1}
            Style={{
              height: 40,
              width: 40,
            }}
          /> */}
            <Tabs
              value={VendorTab}
              onChange={handleSelectedLoginTab}
              textColor="#000"
              indicatorColor="#000"
              aria-label="primary"
              sx={{
                color: "white",
              }}
            >
              {allCategory.map((val) => {
                return (
                  <Tab
                    value={val.CATEGORY_ID}
                    label={val.CATEGORY}
                    className="vendor-tab-item"
                    sx={{
                      fontSize: { xs: 14, md: 14 },
                      fontWeight: 600,
                      textTransform: "none",
                      marginLeft: 2,
                      border: 1,
                      borderColor: "#AFAFAF",
                      backgroundColor:
                        VendorTab == val.CATEGORY_ID ? "#22AAD2" : null,
                      color: VendorTab == val.CATEGORY_ID ? "#fff" : "#7C7C7C",
                      // borderRadius: VendorTab == val.CATEGORY_ID ? 2 : null,
                      borderRadius: "8px",
                    }}
                  />
                );
              })}
            </Tabs>
          </Box>
          <Box
            // container
            p={1}
            // spacing={2}
            // my={4}
            sx={
              {
                // boxShadow: "0 0 4px rgba(0,0,0,0.1)",
                // borderRadius: 3,
                // marginLeft: 0,
                // width: "100%",
              }
            }
            className="card-background"
          >
            <Box className="customer-grid-container">
              {selectedData.map((val, index) => {
                let tempDropdown = [];

                if (val.FIELD_TYPE === "Dropdown") {
                  if (val.DROPDOWN_OPTIONS != "") {
                    tempDropdown.push({ label: val, value: val });
                  }
                }
                return (
                  <Box className="customer-grid-item">
                    <CustomLabel
                      LabelText={`${val.LABEL}${val.IS_REQUIRED ? "*" : ""}`}
                    />

                    {val.FIELD_TYPE === "Dropdown" && (
                      <>
                        <CustomDropdown
                          Options={
                            val.DROPDOWN_OPTIONS == ""
                              ? []
                              : val.DROPDOWN_OPTIONS
                          }
                          Value={val.VALUE}
                          Label={val.LABEL}
                          OnChange={(e) => {
                            handleInputChange(
                              val.CATEGORY_ID,
                              val.FIELD_ID,
                              index,
                              e
                            );
                          }}
                          error={val.ERROR_FLAG}
                        />
                        {val.ERROR_FLAG && (
                          <Typography
                            sx={{
                              fontSize: 12,
                              // ml: 2,
                              padding: 0.6,
                              // m:1,
                              pl: 1,
                              color: COLORS.red,
                              backgroundColor: "white",
                            }}
                          >
                            Please select {val.LABEL}
                          </Typography>
                        )}
                      </>
                    )}

                    {val.FIELD_TYPE === "Input" &&
                      val.INPUT_TYPE === "file" && (
                        <>
                          <form
                            style={{
                              display: "flex",
                              // justifyContent:"space-between",
                              alignItems: "center",
                            }}
                          >
                            <label
                              htmlFor="file-upload"
                              className={`custom-file-upload${
                                val.ERROR_FLAG ? " text-error" : ""
                              }`}
                              style={{
                                fontSize: 14,
                              }}
                            >
                              <i className="fa fa-cloud-upload" /> Upload Image
                            </label>
                            <input
                              id="file-upload"
                              name="upload_cont_img"
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                handleFileUpload(
                                  val.CATEGORY_ID,
                                  val.FIELD_ID,
                                  index,
                                  e
                                );
                              }}
                            />
                            <label
                              style={{
                                fontSize: 14,
                                marginLeft: 5,
                              }}
                            >
                              <i className="fa fa-cloud-upload" />{" "}
                              {val.FILE_NAME}
                            </label>
                          </form>
                          {val.ERROR_FLAG && (
                            <p className="helper-text">Please upload file</p>
                          )}

                          {/* <CustomDropdown
                        Options={
                          val.DROPDOWN_OPTIONS == "" ? [] : val.DROPDOWN_OPTIONS
                        }
                        Value={val.VALUE}
                        Label={val.LABEL}
                        OnChange={(e) => {
                          handleInputChange(
                            val.CATEGORY_ID,
                            val.FIELD_ID,
                            index,
                            e
                          );
                        }}
                      />
                      {val.ERROR_FLAG && (
                        <Typography
                          sx={{
                            fontSize: 12,
                            // ml: 2,
                            padding: 0.6,
                            // m:1,
                            pl: 1,
                            color: COLORS.red,
                            backgroundColor: "white",
                          }}
                        >
                          Please select {val.TITLE}
                        </Typography>
                      )} */}
                        </>
                      )}
                    {val.FIELD_TYPE === "Input" && val.INPUT_TYPE != "file" && (
                      <>
                        <Box style={{}}>
                          <CustomerInputBox
                            Disabled={val.IS_DISABLED}
                            id={val.FIELD_ID}
                            onBlur={() => {
                              FocusDataInRedux();
                            }}
                            Placeholder={val.LABEL}
                            Type={val.INPUT_TYPE}
                            onChange={(e) => {
                              if (val.INPUT_TYPE == "file") {
                                handleFileUpload(
                                  val.CATEGORY_ID,
                                  val.FIELD_ID,
                                  index,
                                  e
                                );
                              } else {
                                handleInputChange(
                                  val.CATEGORY_ID,
                                  val.FIELD_ID,
                                  index,
                                  e.target.value
                                );
                              }
                            }}
                            isRequired={val.IS_REQUIRED}
                            error={val.ERROR_FLAG}
                            Value={val.INPUT_TYPE == "file" ? "" : val.VALUE}
                            helperText={
                              val.ERROR_FLAG ? "Please enter " + val.LABEL : ""
                            }
                          />
                          <p>{val.FILE_NAME}</p>
                        </Box>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                justifyContent: "flex-end",
                display: "flex",
                m: 1,
              }}
            >
              <button
                className="signup-button"
                style={{
                  // marginLeft: 15,
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 4,
                }}
                onClick={() => {
                  saveDataInRedux();
                }}
              >
                Save
              </button>
              {showSubmitButton && (
                <button
                  className="signup-button"
                  style={{
                    marginLeft: 15,
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    borderRadius: 4,
                  }}
                  onClick={() => {
                    createNewCustomer();
                  }}
                >
                  Submit
                </button>
              )}
            </Box>
          </Box>
        </Card>

        <Modal
          open={ExcelImportModalFlag}
          onClose={handleExcelImportModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="excel-upload-modal-container">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Excel Upload
            </Typography>
            <Divider />
            <div
              style={{
                display: "flex",
                marginTop: 10,
                justifyContent: "space-between",
              }}
            >
              <label
                htmlFor="upload"
                className="signup-button"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 4,
                  marginRight: 5,
                  marginLeft: 5,
                  minHeight: 47,
                  maxHeight: 47,
                }}
              >
                Upload File
              </label>
              <input
                type="file"
                name="upload"
                id="upload"
                hidden={true}
                onChange={readUploadFile}
              />
              {/* <button
                className="reset-button"
                style={{
                  backgroundColor: "red",
                }}
                onClick={() => {
                  // updateApplication();
                  // handleSaveAndApprove(2);
                }}
              >
                Reset
              </button> */}

              <button
                className="signup-button"
                style={{
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: 4,
                  marginRight: 5,
                  marginLeft: 5,
                  minHeight: 47,
                  maxHeight: 47,
                }}
                onClick={() => {
                  ExcelUploadCreateCustomer();
                }}
              >
                Create Customer
              </button>
            </div>
            {/* {JSON.stringify(uploadedExcelJson)} */}
            <div className="excel-upload-modal-child">
              {uploadedExcelJson.map((val) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: val.ERROR_FLAG ? "red" : "#fff",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#272727",
                          fontWeight: 500,
                          fontSize: 16,
                          p: 1,
                          color: val.ERROR_FLAG ? "#fff" : null,
                          // backgroundColor: "#637091",
                        }}
                      >
                        {val.FIELD_ID}
                      </Typography>
                      
                        {val.FIELD_TYPE == "Dropdown" ?
                          <Typography
                            sx={{
                              color: "#272727",
                              fontWeight: 500,
                              fontSize: 16,
                              p: 1,
                              color: val.ERROR_FLAG ? "#fff" : null,
                            }}
                          >
                            {val?.VALUE?.value}
                          </Typography>:     <Typography
                            sx={{
                              color: "#272727",
                              fontWeight: 500,
                              fontSize: 16,
                              p: 1,
                              color: val.ERROR_FLAG ? "#fff" : null,
                            }}
                          >
                            {val?.VALUE}
                          </Typography>
                        }
                      
                    </div>
                    <Divider />
                  </>
                );
              })}
            </div>
          </Box>
        </Modal>

        {/* Tab data */}
      </MainScreen>
    </>
  );
}

// export default CustomerCreation;

const mapStateToProps = (state) => ({
  CUSTOMER_DATA: state.customer.customer_data,
});

export default connect(mapStateToProps, { setCustomerDataAction })(
  CustomerCreation
);
