import React from "react";
import {
  Backdrop,
  Box,
  Card,
  Chip,
  CircularProgress,
  CssBaseline,
  Divider,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import { COLORS } from "../Utils/Theme";
import { useEffect } from "react";
import axios from "axios";
import AXIOS from "../Utils/AXIOS";
import IMAGES from "../../assets/IMAGES";
import CustomLabel from "../../components/CustomLabel/CustomLabel";
import ImageIcon from "../../components/ImageIcon/ImageIcon";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import Lottie from "react-lottie-player";
import noData from "../../assets/LottieAnimations/noData.json";
import CustomInput from "../../components/CustomInput/CustomInput";
import toast, { Toaster } from "react-hot-toast";
import Header from "../../components/AppDrawer/Header";
import CustomerInputBox from "../../components/CustomerInputBox/CustomerInputBox";
import MainScreen from "../../components/AppDrawer/MainScreen";
import { CUSTOMER_APPROVAL } from "../Utils/Routes";
import { connect } from "react-redux";
const LOGGED_IN_ID = "E0002";
const drawerWidth = 282;

const NoDataFoundBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 500,
  // bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
};

function CustomerApproval(props) {
  const navigate = useNavigate();

  const [Tbody, setTbody] = useState([]);
  const [selectedCustomerID, setSelectedCustomerID] = useState("");
  const [allCategory, setAllCategory] = useState([]);
  const [customerFieldsData, setCustomerFieldsData] = useState([]);
  const [CurrentApprovalDetails, setCurrentApprovalDetails] = useState();
  const [noDataFound, setNoDataFound] = useState(null);
  const [currentApplicationData, setCurrentApplicationData] = useState([]);


  const [canEdit, setCanEdit] = useState(false);

  const StatusData = [
    {
      label: "Pending",
      value: "1",
    },
    {
      label: "Approved",
      value: "2",
    },
    {
      label: "Rejected",
      value: "3",
    },
  ];

  const [selectedStatus, setSelectedStatus] = useState({
    label: "Pending",
    value: "1",
  });

  useEffect(() => {
    getCustomerCreationFields();
  }, []);

  useEffect(() => {
    getAllCustomerApplicationData(selectedStatus.value);
    setAllCategory([]);
    setSelectedCustomerID("");
    setCurrentApprovalDetails("");
    setCurrentApplicationData([]);

    let url = window.location.href;
    url = url.split("?");
    if (url.length > 1) {
      navigate(-1);
    }
    // if (selectedCustomerID != "") {


    console.log("?asdkbasdjsajd", props.AUTH_DATA);
    // }
  }, [selectedStatus, props.AUTH_DATA]);

  const getAllCustomerApplicationData = (Flag) => {
    console.log(
      "ASdasdasd",
      AXIOS.axiosUrl +
        AXIOS.customer_get_by_approver +
        props.AUTH_DATA?.USER_ID +
        "&APPROVAL_FLAG=" +
        Flag
    );
    axios
      .get(
        AXIOS.axiosUrl +
          AXIOS.customer_get_by_approver +
          props.AUTH_DATA?.USER_ID +
          "&APPROVAL_FLAG=" +
          Flag
      )
      .then((response) => {
        if (response.data.length > 0) {
          setNoDataFound(false);
        } else {
          setNoDataFound(true);
        }

        setTbody(response.data);
      })
      .catch((err) => {
        console.log("aaas", err);
      });
  };

  useEffect(() => {
    if (selectedCustomerID != "" && props.AUTH_DATA?.USER_ID != undefined) {
      getApplicationDetail();
    }
  }, [selectedCustomerID, props.AUTH_DATA]);

  const getApplicationDetail = () => {
    let tempCustomerFieldsData = [...customerFieldsData];
    const findApplicationData = Tbody.find(
      (item) => item.APPLICATION_ID == selectedCustomerID
    );

    const tempCurrentApprovalData = findApplicationData.APPROVERS.find(
      (item) => item.EMP_ID == props.AUTH_DATA?.USER_ID
    );
    console.log("SADsadasdas", tempCurrentApprovalData);
    setCurrentApprovalDetails(tempCurrentApprovalData);
    setCurrentApplicationData(findApplicationData.APPROVERS);

    let responseData = [];

    Object.keys(findApplicationData).forEach(function (key, index, value) {
      responseData.push({ FIELD_ID: key, VALUE: findApplicationData[key] });
    });

    tempCustomerFieldsData.map((val) => {
      const foundData = responseData.find((item) => {
        return item.FIELD_ID == val.FIELD_ID;
      });
      if (foundData) {
        val.VALUE = foundData.VALUE;
      }
    });

    let tempCategoryArr = [];
    console.log("SAdasdas", tempCustomerFieldsData);
    tempCustomerFieldsData.map((val) => {
      let categoryIndex = tempCategoryArr.findIndex(
        (item) => val.CATEGORY_ID == item.CATEGORY_ID
      );

      let value;
      if (val.FIELD_TYPE == "Dropdown") {
        let CurrentDropDownOption = val.DROPDOWN_OPTIONS.find(
          (option) => option.value == val.VALUE
        );
        if (CurrentDropDownOption) {
          value = CurrentDropDownOption;
        }
      } else {
        value = val.VALUE;
      }
      if (categoryIndex == -1) {
        tempCategoryArr.push({
          CATEGORY_ID: val.CATEGORY_ID,
          CATEGORY: val.CATEGORY,
          FIELDS: [
            {
              FILE: [],
              FILE_NAME: "",
              ...val,
              VALUE: value,
              IS_DISABLED: tempCurrentApprovalData.ACTION_TAKEN,
            },
          ],
        });
      } else {
        tempCategoryArr[categoryIndex].FIELDS.push({
          FILE: [],
          FILE_NAME: "",
          ...val,
          VALUE: value,

          IS_DISABLED: tempCurrentApprovalData.ACTION_TAKEN,
        });
      }
    });

    setAllCategory(tempCategoryArr);
  };

  useEffect(() => {}, [window.location.href]);
  const getCustomerCreationFields = async () => {
    await axios
      .get(AXIOS.axiosUrl + AXIOS.customer_field_get)
      .then((response) => {
        setCustomerFieldsData(response.data);

        // console.log("tempCategoryArr,", tempCategoryArr);
        // setAllCategory(response.data);
        // setVendorTab(tempCategoryArr[0].CATEGORY_ID);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getChip = (Status) => {
    if (Status == 1) {
      //pending

      return (
        <Chip
          label="Pending"
          color="warning"
          deleteIcon={<CheckCircleIcon />}
        />
      );
    } else if (Status == 2) {
      //approved
      return (
        <Chip
          label="Approved"
          color="success"
          deleteIcon={<CheckCircleIcon />}
        />
      );
    } else if (Status == 3) {
      //Rejected
      return (
        <Chip label="Rejected" color="error" deleteIcon={<CheckCircleIcon />} />
      );
    }
  };

  const handleApproveReject = async (Flag, CallSaveApplication) => {
    setNoDataFound(null);
    let callApprovalApi = true;
    if (CallSaveApplication == true) {
      callApprovalApi = updateApplication();
    }

    console.log("ASdasdsad", callApprovalApi);

    //  console.log("tempCurrentApprovalData", tempCurrentApprovalData,findApplicationData);

    if (callApprovalApi) {
      await axios
        .post(AXIOS.axiosUrl + AXIOS.customer_approve_update, {
          APPLICATION_ID: selectedCustomerID,
          APPROVER_DATA: CurrentApprovalDetails,
          FLAG: Flag,
        })
        .then((response) => {
          setNoDataFound(false);
          console.log("ASdsadsad", response.data.status);
          if (
            response.data.FINAL_APPROVAL == true &&
            response.data.data_sent == true
          ) {
            toast.success("Application Created in SAP");
             let tempTbody = [...Tbody];
             const findApplicationData = tempTbody.findIndex(
               (item) => item.APPLICATION_ID == selectedCustomerID
             );

             const tempCurrentApprovalData = Tbody[
               findApplicationData
             ].APPROVERS.findIndex(
               (item) => item.EMP_ID == props.AUTH_DATA.USER_ID
             );

             //  if(findApplicationData)
             //  tempCustomerFieldsData[];
             tempTbody[findApplicationData].APPROVERS[
               tempCurrentApprovalData
             ].ACTION_TAKEN = true;
             tempTbody[findApplicationData].APPROVERS[
               tempCurrentApprovalData
             ].ELIGIBLE = false;
             tempTbody[findApplicationData].APPROVERS[
               tempCurrentApprovalData
             ].APPROVAL_FLAG = Flag;

             let tempAllCategory = [...allCategory];

             tempAllCategory.map((item) => {
               item.FIELDS.map((val) => {
                 val.IS_DISABLED = true;
               });
             });
             setAllCategory(tempAllCategory);

             setTbody(tempTbody);
          } else if (
            response.data.FINAL_APPROVAL == true &&
            response.data.data_sent == false
          ) {
            toast.error("Application not created in SAP, Please check fields");
          } else {
            let tempTbody = [...Tbody];
            const findApplicationData = tempTbody.findIndex(
              (item) => item.APPLICATION_ID == selectedCustomerID
            );

            const tempCurrentApprovalData = Tbody[
              findApplicationData
            ].APPROVERS.findIndex(
              (item) => item.EMP_ID == props.AUTH_DATA.USER_ID
            );

            //  if(findApplicationData)
            //  tempCustomerFieldsData[];
            tempTbody[findApplicationData].APPROVERS[
              tempCurrentApprovalData
            ].ACTION_TAKEN = true;
            tempTbody[findApplicationData].APPROVERS[
              tempCurrentApprovalData
            ].ELIGIBLE = false;
            tempTbody[findApplicationData].APPROVERS[
              tempCurrentApprovalData
            ].APPROVAL_FLAG = Flag;

            let tempAllCategory = [...allCategory];

            tempAllCategory.map((item) => {
              item.FIELDS.map((val) => {
                val.IS_DISABLED = true;
              });
            });
            setAllCategory(tempAllCategory);

            setTbody(tempTbody);
          }
          // if()
        })
        .catch((err) => {
          setNoDataFound(false);

          console.log("ASdasd", err);
        });
    }
  };

  const handleInputChange = (CategoryId, FieldId, ListIndex, Index, value) => {
    const TypedValue = value;

    let tempSelectedData = [...allCategory];

    if (
      tempSelectedData[ListIndex].FIELDS[Index]?.MAX_LENGTH != undefined &&
      tempSelectedData[ListIndex].FIELDS[Index]?.FIELD_TYPE != "Dropdown" &&
      tempSelectedData[ListIndex].FIELDS[Index]?.INPUT_TYPE != "file"
    ) {


      if (
        TypedValue.length <=
        Number(tempSelectedData[ListIndex].FIELDS[Index].MAX_LENGTH)
      ) {
        tempSelectedData[ListIndex].FIELDS[Index].VALUE = TypedValue;
        tempSelectedData[ListIndex].FIELDS[Index].ERROR_FLAG = false;
      } else {
        toast.error("Maximum word limit exceeded", {
          style: {
            border: "1px solid #713200",
            padding: "16px",
            color: "#713200",
          },
          iconTheme: {
            primary: "#713200",
            secondary: "#FFFAEE",
          },
        });
      }
    } else {
      tempSelectedData[ListIndex].FIELDS[Index].VALUE = TypedValue;
      tempSelectedData[ListIndex].FIELDS[Index].ERROR_FLAG = false;
    }

    setAllCategory(tempSelectedData);
  };

  const checkErrorsInCustomerData = (selectedData) => {
    let noErrorFound = true;
    let tempSelectedData = [...selectedData];
    tempSelectedData.map((val) => {
      if (val.FIELD_TYPE == "Input" && val.IS_REQUIRED == true) {
        if (val.VALUE == "") {
          val.ERROR_FLAG = true;
        }
      } else {
        if (val.INPUT_TYPE == "file") {
          if (val.FILE.length == 0) {
          }
        }
      }
    });

    // setSelectedData(tempSelectedData);
    if (noErrorFound) {
      let tempReduxCustomerData = [...selectedData];

      selectedData.map((val) => {
        const foundIndexInRedux = tempReduxCustomerData.findIndex(
          (item) => item.FIELD_ID == val.FIELD_ID
        );
        if (foundIndexInRedux == -1) {
          tempReduxCustomerData.push({ ...val });
        } else {
          tempReduxCustomerData[foundIndexInRedux] = { ...val };
        }
      });
      // props.setCustomerDataAction(tempReduxCustomerData);
    }
    // console.log("sss", props.CUSTOMER_DATA);

    return noErrorFound;
  };
  const handleSaveAndApprove = (Flag) => {
    let tempAllCategory = [];

    allCategory.map((val) => {
      val.FIELDS.map((item) => {
        tempAllCategory.push({ ...item });
      });
    });

    checkErrorsInCustomerData(tempAllCategory);
  };

  useEffect(() => {}, [allCategory]);

  const saveDataInRedux = () => {
    let noErrorFound = true;
    let tempSelectedData = [...allCategory];

    tempSelectedData.map((item) => {
      console.log("ASdasdas", item);
      item.FIELDS.map((val) => {
        if (
          val.FIELD_TYPE == "Input" &&
          val.INPUT_TYPE != "file" &&
          val.IS_REQUIRED == true
        ) {
          if (val.VALUE == "") {
            val.ERROR_FLAG = true;
            noErrorFound = false;
          }
        } else if (
          val.FIELD_TYPE == "Input" &&
          val.INPUT_TYPE == "file" &&
          val.IS_REQUIRED == true
        ) {
          if (val.VALUE == "") {
            if (val?.FILE_NAME == "" || val?.FILE_NAME == undefined) {
              val.ERROR_FLAG = true;
              noErrorFound = false;
            }
          }
        } else if (val.FIELD_TYPE == "Dropdown" && val.IS_REQUIRED == true) {
          if (val.VALUE == "") {
            val.ERROR_FLAG = true;
            noErrorFound = false;
          }
        }
      });
    });

    setAllCategory(tempSelectedData);
    return noErrorFound;

    // tempSelectedData.map((val) => {

    // });

    // setSelectedData(tempSelectedData);
    // if (noErrorFound) {
    //   let tempReduxCustomerData = [...props.CUSTOMER_DATA];

    //   selectedData.map((val) => {
    //     const foundIndexInRedux = tempReduxCustomerData.findIndex(
    //       (item) => item.FIELD_ID == val.FIELD_ID
    //     );
    //     if (foundIndexInRedux == -1) {
    //       tempReduxCustomerData.push({ ...val });
    //     } else {
    //       tempReduxCustomerData[foundIndexInRedux] = { ...val };
    //     }
    //   });
    //   props.setCustomerDataAction(tempReduxCustomerData);
    //   toast.success("Application Saved Successfully");
    // }
    // console.log("sss", props.CUSTOMER_DATA);

    // return noErrorFound;
  };

  const updateApplication = async () => {
    const noErrorFound = saveDataInRedux();

    let approvalFlag = false;

    if (noErrorFound == true) {
      let customerDataRedux = [...allCategory];
      let customerFormData = new FormData();
      customerFormData.append("APPLICATION_ID", selectedCustomerID);

      customerDataRedux.map((item) => {
        item.FIELDS.map((val) => {
          // item.map((val) => {
          if (
            val.INPUT_TYPE == "file" &&
            val.FIELD_TYPE == "Input" &&
            val.FILE_NAME != ""
          ) {
            customerFormData.append([val.FIELD_ID], val.FILE);
          }
          //  if (
          //    val.INPUT_TYPE == "file" &&
          //    val.FIELD_TYPE == "Input" &&
          //    val.VALUE == ""
          //  ) {

          //  }
          else if (val.FIELD_TYPE == "Dropdown") {
            customerFormData.append([val.FIELD_ID], val.VALUE?.value);
          } else {
            customerFormData.append([val.FIELD_ID], val.VALUE);
          }
          // });
        });
      });
             customerFormData.set(
               "APPROVERS",
               JSON.stringify(currentApplicationData)
             );
        customerFormData.set("APPLICATION_SUBMITTED", JSON.stringify(true));

      // console.log("ASdasdasdasd", JSON.parse(customerFormData));
      for (const pair of customerFormData.entries()) {
        console.log("asdasdasdsadasd", pair[0], pair[1]);
      }
   
      await axios
        .post(
          AXIOS.axiosUrl + AXIOS.sap_approved_customer_update,
          customerFormData
        )
        .then((response) => {
          //  console.log("response", response.data);
          toast.success("Customer Updated Successfully");
          approvalFlag = true;

          //  if (response.data.APPROVER == false) {
          //    toast.error("No approver assigned");
          //  } else if (response.data.CUSTOMER_CREATED == true) {
          //    toast.success("Customer Created Successfully");
          //  }
        })
        .catch((err) => {
          console.log("err", err);
          return false;
        });
    } else if (noErrorFound == false) {
      toast.error("Please fill mandatory field");
      return false;
    }
    return approvalFlag;
  };

  const handleFileUpload = (CategoryId, FieldId, ListIndex, Index, value) => {
    let tempSelectedData = [...allCategory];

    tempSelectedData[ListIndex].FIELDS[Index].FILE = value.target.files[0];
    tempSelectedData[ListIndex].FIELDS[Index].FILE_NAME =
      value.target.files[0].name;
    tempSelectedData[ListIndex].FIELDS[Index].ERROR_FLAG = false;
    setAllCategory(tempSelectedData);
  };

  return (
    <MainScreen drawerWidth={drawerWidth} ActiveKey={CUSTOMER_APPROVAL}>
      <Toaster />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: 15,
          backgroundColor: "#fff",
          padding: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            // marginTop: 5,
          }}
        >
          <div className="gray-arrow-container">Customer</div>
          <div className="blue-arrow-container">Approval</div>
        </div>
      </div>
      <Card
        sx={{
          mt: 2,
          ml: 2,
          mr: 2,
        }}
        className="customer-approval-card-container"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginLeft: 5,
          }}
        >
          <h1
            style={{
              color: "black",
            }}
          >
            Customer Approval
          </h1>
          <Box
            style={{
              margin: 10,
            }}
          >
            <CustomDropdown
              Options={StatusData}
              Label={"Status"}
              OnChange={(e) => {
                setSelectedStatus(e);
              }}
              Value={selectedStatus}
            />
          </Box>

          {/* {CurrentApprovalDetails.ACTION_TAKEN==true && ( */}

          {/* )} */}
        </Box>
        <div className="customer-left-container">
          {/* <Box className="vendor-list-container"> */}
          {Tbody.map((val) => {
            return (
              <>
                <div
                  className="application-list-item"
                  style={{
                    backgroundColor:
                      val.APPLICATION_ID == selectedCustomerID
                        ? "#22AAD2"
                        : "#fff",
                    color: val.APPLICATION_ID == selectedCustomerID ? "#fff" : "",
                  }}
                  onClick={() => {
                    navigate("?APPLICATION_ID=" + val.APPLICATION_ID);
                    setSelectedCustomerID(val.APPLICATION_ID);
                  }}
                >
                  {val.APPLICATION_ID}
                </div>

                {/* <ListItem
                className="vendor-details-nav-bar"
                disablePadding
                sx={{
                  borderRadius: 4,
                  overflow: "hidden",
                  backgroundColor:
                    val.APPLICATION_ID == selectedCustomerID
                      ? "#637091"
                      : "#d3d3d3",
                  mt: 2,
                  width: 100,
                }}
                // aria-controls={
                //   openCustomerDropdown ? "basic-menu" : undefined
                // }
                // aria-haspopup="true"
                // aria-expanded={openCustomerDropdown ? "true" : undefined}
                onClick={() => {
                  navigate("?APPLICATION_ID=" + val.APPLICATION_ID);
                  setSelectedCustomerID(val.APPLICATION_ID);
                }}
              >
                <ListItemButton>
                  <ListItemText
                    primary={`${val.APPLICATION_ID}`}
                    sx={{ textAlign: "center", color: "black" }}
                    className="text-label"
                  />
                </ListItemButton>
              </ListItem>
              <Divider /> */}
              </>
            );
          })}
          {/* </Box> */}
        </div>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: 10,
          }}
        >
          {CurrentApprovalDetails != undefined && (
            <>
              {CurrentApprovalDetails.ACTION_TAKEN == false && (
                <Box>
                  <button
                    className="save-button"
                    style={{
                      marginLeft: 15,
                      backgroundColor: "#FF474C",
                    }}
                    onClick={() => {
                      handleApproveReject(3, false);
                    }}
                  >
                    Reject
                  </button>
                  {CurrentApprovalDetails.CAN_EDIT == false && (
                    <button
                      className="save-button"
                      style={{
                        marginLeft: 15,
                      }}
                      onClick={() => {
                        handleApproveReject(2, false);
                      }}
                    >
                      Approve
                    </button>
                  )}
                  {CurrentApprovalDetails.CAN_EDIT == true && (
                    <button
                      className="save-button"
                      style={{
                        marginLeft: 15,
                      }}
                      onClick={() => {
                        handleApproveReject(2, true);
                        // handleSaveAndApprove(2);
                      }}
                    >
                      Save and Approve
                    </button>
                  )}
                  {CurrentApprovalDetails.CAN_EDIT == true && (
                    <button
                      className="save-button"
                      style={{
                        marginLeft: 15,
                        backgroundColor: "#22aad2",
                      }}
                      onClick={() => {
                        updateApplication();
                        // handleSaveAndApprove(2);
                      }}
                    >
                      Save
                    </button>
                  )}
                </Box>
              )}
              {CurrentApprovalDetails.ACTION_TAKEN == true &&
                getChip(CurrentApprovalDetails.APPROVAL_FLAG)}
            </>
          )}
        </Box>

        <div className="customer-approval-container">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {noDataFound && (
              <Box sx={NoDataFoundBoxStyle}>
                <Lottie
                  play
                  //   speed={"1"}
                  animationData={noData}
                  style={{
                    width: 250,
                    height: 250,

                    // marginLeft: "50%",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 500,
                    color: "#b7beca",
                    textAlign: "center",
                  }}
                >
                  No Data Found
                </Typography>
              </Box>
            )}
            {noDataFound == null && (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="primary" />
              </Backdrop>
            )}
          </Box>

          <div className="customer-right-container">
            <>
              {selectedCustomerID == "" ? (
                ""
              ) : (
                <>
                  {/* <Typography
                  sx={{
                    color: COLORS.gray85,
                    fontWeight: 700,
                    fontSize: 20,
                    p: 1,
                    // backgroundColor: COLORS.primary,
                  }}
                >
                  Customer Details
                </Typography> */}

                  {CurrentApprovalDetails != undefined && (
                    <>
                      {CurrentApprovalDetails.CAN_EDIT == false && (
                        <>
                          {allCategory.map((val) => {
                            return (
                              <>
                                <Typography
                                  sx={{
                                    color: "#272727",
                                    fontWeight: 700,
                                    fontSize: 22,
                                    p: 1,
                                    // backgroundColor: "#637091",
                                  }}
                                >
                                  {val.CATEGORY}
                                </Typography>
                                <div className="customer-no-edit-approval-grid-container">
                                  {val.FIELDS.map((item) => {
                                    return (
                                      <div>
                                        <Typography
                                          sx={{
                                            // color: COLORS.white,
                                            fontWeight: 700,
                                            fontSize: 20,
                                            p: 1,
                                            // backgroundColor: COLORS.primary,
                                          }}
                                        >
                                          {item.LABEL}
                                        </Typography>
                                        {item.FIELD_TYPE === "Input" &&
                                          item.INPUT_TYPE === "file" && (
                                            <label
                                              style={{
                                                fontSize: 14,
                                                marginLeft: 5,
                                                backgroundColor: "#ADD8E6",
                                                margin: 7,
                                              }}
                                              className="custom-file-upload"
                                              onClick={() => {
                                                window.open(
                                                  item.VALUE,
                                                  "_blank",
                                                  "noreferrer"
                                                );
                                              }}
                                            >
                                              <i className="fa fa-cloud-upload" />{" "}
                                              View File
                                            </label>
                                          )}
                                        {item.FIELD_TYPE == "Dropdown" && (
                                          <Typography
                                            sx={{
                                              // color: COLORS.white,
                                              // fontWeight: 700,
                                              fontSize: 20,
                                              p: 1,
                                              // backgroundColor: COLORS.primary,
                                            }}
                                          >
                                            {item.VALUE?.value}
                                          </Typography>
                                        )}
                                        {item.FIELD_TYPE != "Dropdown" &&
                                          item.INPUT_TYPE != "file" && (
                                            <Typography
                                              sx={{
                                                // color: COLORS.white,
                                                // fontWeight: 700,
                                                fontSize: 20,
                                                p: 1,
                                                // backgroundColor: COLORS.primary,
                                              }}
                                            >
                                              {item.VALUE}
                                            </Typography>
                                          )}
                                        <Divider />
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                  {CurrentApprovalDetails != undefined && (
                    <>
                      {CurrentApprovalDetails.CAN_EDIT == true && (
                        <>
                          {allCategory.map((item, listIndex) => {
                            console.log("ASdasdasdas", item);
                            return (
                              <>
                                <Typography
                                  sx={{
                                    color: "#272727",
                                    fontWeight: 700,
                                    fontSize: 22,
                                    p: 1,
                                    // backgroundColor: "#637091",
                                  }}
                                >
                                  {item.CATEGORY}
                                </Typography>
                                <Box className="customer-approval-grid-container">
                                  {item.FIELDS.map((val, index) => {
                                    return (
                                      <Box
                                        // sx={{
                                        //   display: "flex",
                                        //   alignItems: "center",
                                        // }}
                                        className="customer-grid-item"
                                      >
                                        <Box
                                        // sx={{
                                        //   mr: 2,
                                        // }}
                                        >
                                          <CustomLabel
                                            LabelText={`${val.LABEL}${
                                              val.IS_REQUIRED ? "*" : ""
                                            }`}
                                          />
                                        </Box>
                                        {val.FIELD_TYPE === "Dropdown" && (
                                          <>
                                            <CustomDropdown
                                              IS_DISABLED={val.IS_DISABLED}
                                              Options={
                                                val.DROPDOWN_OPTIONS == ""
                                                  ? []
                                                  : val.DROPDOWN_OPTIONS
                                              }
                                              Value={val.VALUE}
                                              Label={val.LABEL}
                                              OnChange={(e) => {
                                                handleInputChange(
                                                  val.CATEGORY_ID,
                                                  val.FIELD_ID,
                                                  listIndex,
                                                  index,
                                                  e
                                                );
                                              }}
                                              error={val.ERROR_FLAG}
                                            />
                                            {val.ERROR_FLAG && (
                                              <Typography
                                                sx={{
                                                  fontSize: 12,
                                                  // ml: 2,
                                                  padding: 0.6,
                                                  // m:1,
                                                  pl: 1,
                                                  color: COLORS.red,
                                                  backgroundColor: "white",
                                                }}
                                              >
                                                Please select {val.TITLE}
                                              </Typography>
                                            )}
                                          </>
                                        )}
                                        {val.FIELD_TYPE === "Input" &&
                                          val.INPUT_TYPE === "file" && (
                                            <>
                                              <form
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <label
                                                  htmlFor="file-upload"
                                                  className={`custom-file-upload${
                                                    val.ERROR_FLAG
                                                      ? " text-error"
                                                      : ""
                                                  }`}
                                                  style={{
                                                    fontSize: 14,
                                                  }}
                                                >
                                                  <i className="fa fa-cloud-upload" />{" "}
                                                  Upload Image
                                                </label>
                                                <input
                                                  id="file-upload"
                                                  name="upload_cont_img"
                                                  type="file"
                                                  disabled={val.IS_DISABLED}
                                                  style={{ display: "none" }}
                                                  onChange={(e) => {
                                                    handleFileUpload(
                                                      val.CATEGORY_ID,
                                                      val.FIELD_ID,
                                                      listIndex,
                                                      index,
                                                      e
                                                    );
                                                  }}
                                                />

                                                <label
                                                  style={{
                                                    fontSize: 14,
                                                    marginLeft: 5,
                                                    backgroundColor: "#ADD8E6",
                                                  }}
                                                  className="custom-file-upload"
                                                  onClick={() => {
                                                    window.open(
                                                      val.VALUE,
                                                      "_blank",
                                                      "noreferrer"
                                                    );
                                                  }}
                                                >
                                                  <i className="fa fa-cloud-upload" />{" "}
                                                  View File
                                                </label>
                                              </form>
                                              <label
                                                style={{
                                                  fontSize: 14,
                                                  marginLeft: 5,
                                                }}
                                              >
                                                <i className="fa fa-cloud-upload" />{" "}
                                                {val.FILE_NAME == ""
                                                  ? val.VALUE.split(
                                                      "http://103.205.66.63:9005/"
                                                    )[1]
                                                  : val.FILE_NAME}
                                              </label>
                                              {val.ERROR_FLAG && (
                                                <p className="helper-text">
                                                  Please upload file
                                                </p>
                                              )}
                                            </>
                                          )}
                                        {val.FIELD_TYPE === "Input" &&
                                          val.INPUT_TYPE != "file" && (
                                            <>
                                              <Box style={{}}>
                                                <CustomerInputBox
                                                  Disabled={val.IS_DISABLED}
                                                  id={val.FIELD_ID}
                                                  Placeholder={val.LABEL}
                                                  Type={val.INPUT_TYPE}
                                                  onChange={(e) => {
                                                    // if (
                                                    //   val.INPUT_TYPE == "file"
                                                    // ) {
                                                    // handleFileUpload(
                                                    //   val.CATEGORY_ID,
                                                    //   val.FIELD_ID,
                                                    //   index,
                                                    //   e
                                                    // );
                                                    // } else {
                                                    handleInputChange(
                                                      val.CATEGORY_ID,
                                                      val.FIELD_ID,
                                                      listIndex,
                                                      index,
                                                      e.target.value
                                                    );
                                                    // }
                                                  }}
                                                  isRequired={val.IS_REQUIRED}
                                                  error={val.ERROR_FLAG}
                                                  Value={
                                                    val.INPUT_TYPE == "file"
                                                      ? ""
                                                      : val.VALUE
                                                  }
                                                  helperText={
                                                    val.ERROR_FLAG
                                                      ? "Please enter " +
                                                        val.LABEL
                                                      : ""
                                                  }
                                                />
                                                <p>{val.FILE_NAME}</p>
                                              </Box>
                                            </>
                                          )}
                                      </Box>
                                    );
                                  })}
                                </Box>
                              </>
                            );
                          })}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </div>
        </div>
      </Card>
    </MainScreen>
  );
}

// export default CustomerApproval;

const mapStateToProps = (state) => ({
  AUTH_DATA: state.auth.auth_data,
  IS_AUTH: state.auth.is_auth,
});

export default connect(mapStateToProps)(CustomerApproval);
