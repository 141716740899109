const axiosUrl = "https://timesheet.samishti.com:9005/";
const sapFieldGet = "api/customer/fields_get";
const customer_field_get = "api/customer/customer_fields_get";
const assign_fields_emp = "api/customer/insert_fields";
const create_approver = "api/approver/approver_create";
const default_approver_update = "api/approver/default_approver_update";
const login_session_auth = "api/login/login_validate_session_create";
const login_session_logout =
  "api/login/logout_session_delete";

const login_session_get =
  "api/login/session_get?SESSION_ID=";
const get_approver = "api/approver/approver_get";
const customer_create = "api/customer/customer_create";
const customer_get_by_approver =
  "api/customer/customer_get_by_approver?APPROVER_ID=";
const customer_approve_update = "api/customer/customer_approve_update";
const approver_group_get = "api/approver_group/approver_group_get";
const approver_group_create = "api/approver_group/approver_group_create";
const sap_approved_customer_get = "api/customer/approved_customer_get";
const sap_approved_customer_update = "api/customer/customer_update";
const customer_get_by_application_id =
  "api/customer/customer_get_by_application_id?APPLICATION_ID=";
const users_get =
  "api/users/users_get";
const otp_send_to_receiver = "api/customer/otp_send_to_receiver";
const receiver_otp_verification = "api/customer/otp_verification";
export default {
  axiosUrl,
  sapFieldGet,
  assign_fields_emp,
  get_approver,
  create_approver,
  login_session_auth,
  login_session_logout,
  customer_create,
  customer_get_by_approver,
  customer_approve_update,
  login_session_get,
  customer_field_get,
  approver_group_create,
  approver_group_get,
  default_approver_update,
  sap_approved_customer_get,
  sap_approved_customer_update,
  users_get,
  customer_get_by_application_id,
  otp_send_to_receiver,
  receiver_otp_verification,
};
