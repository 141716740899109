import axios from "axios";
import AXIOS from "../../screens/Utils/AXIOS";

export const LoginAction =
  (...args) =>
  (dispatch) => {
    console.log("ASdasdasdasd", args);
    dispatch({
      type: "AUTH_DATA_GET",
      is_auth: true,
      auth_data: args[0],
    });
  };
